/* eslint-disable max-statements */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import TopBanner from '../containers/TopBanner';
import KsBreadcrumb from './Common/Breadcrumb';
import AlertBox from './Common/AlertBox';
import Modal from './Common/Modal';
import SavedBox from './Common/SavedBox';
import reloader from './Common/reloader';

import PersonalSection from './CurricularProfileShowSections/PersonalSection';
import EducationSection from './CurricularProfileShowSections/EducationSection';
import JobSection from './CurricularProfileShowSections/JobSection';
import OtherSection from './CurricularProfileShowSections/OtherSection';

class CurricularProfileShow extends React.Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.saveAR = this.saveAR.bind(this);
    this.submitAR = this.submitAR.bind(this);
    this.openModal = this.openModal.bind(this);
    this.uploadNewCV = this.uploadNewCV.bind(this);
    this.state = {
      apikey: '',
      cp: {},
      fields: this.props.fields,
      mandatoryFields: {
        años_de_experiencia: false,
        educacion: false,
        email: false,
        experiencias_laborales: false,
        habilidades_ti: false,
        hobbies: false,
        lenguajes: false,
        nombre_completo: false,
        otras_habilidades: false,
        redes_sociales: false,
        resumen: false,
        sitio_web: false,
      },
      currentStep: 0,
      personalSectionStep: 0,
      educationSectionStep: 1,
      jobSectionStep: 2,
      otherSectionStep: 3,
      numberOfSteps: 4,
      sectionsAccessible: [true, false, false, false],
      success: null,
      alertBox: '',
      fileNameBox: '',
      anotherCV: false,
      wasSaved: false,
    };
  }

  submitAR() {
    const okStatus = 200;
    this.setState({ error: null, success: null, sending: true }, () => {
      if (this.state.fields['educacion'].length > 0) {
        axios.patch(`/api/v1/applicant/curricular_profiles/${this.props.cp.id}/responses/${this.props.arId}`, {
          response: { fields: this.state.fields },
        }, {
          headers: { apikey: this.state.apikey },
        })
          .then(res => {
            if (res.status === okStatus) {
              this.setState({ sending: false });
              window.location.href = `${this.props.applicantPortal}/?apikey=${this.state.apikey}`;
            }
          })
          .catch(() => {
            this.setState({ sending: false });
          });
      }
    });
  }

  saveAR(event) {
    const okStatus = 200;
    const hideMs = 1500;
    if (event) event.preventDefault();
    this.setState({ error: null, success: null, sending: true }, () => {
      axios.patch(`/api/v1/applicant/curricular_profiles/${this.props.cp.id}/responses/${this.props.arId}/save`, {
        response: { fields: this.state.fields },
      }, {
        headers: { apikey: this.state.apikey },
      })
        .then(res => {
          if (res.status === okStatus) {
            this.setState({ sending: false, wasSaved: true });
            window.setTimeout(() => {
              this.setState({ wasSaved: false });
            }, hideMs);
          }
        })
        .catch(() => {
          this.setState({ error: 'Ha ocurrido un error en el proceso de guardado. Por favor, intentalo más tarde.' });
          this.setState({ sending: false, wasSaved: false });
        });
      window.scrollTo(0, 0);
    });
  }

  componentDidMount() {
    reloader();
    const search = window.location.search;
    const apikey = (new URLSearchParams(search)).get('apikey');
    const fields = { ...this.props.fields };
    const fileName = localStorage.getItem('attachedFileName');

    if (this.props.isAnswerable === false)
      window.location.href = `${this.props.applicantPortal}/?apikey=${this.state.apikey}`;

    if ((new URLSearchParams(search)).get('code') === 'success') {
      this.setState({
        alertBox: 
          <AlertBox
            message={'Tu CV ha sido procesado exitosamente.'}
            boldMessage={'Para completar tu solicitud, confirma los datos que aparecen a continuación'}
            className={'alert-box alert-box--info p-3'}
          />,
        fileNameBox: 
          <div className={'cp-form__header-right p-3'}>
            <span className={'cp-form__file-title'}>Archivo seleccionado:  <span className='text-muted'>{fileName}</span></span>
            <a className={'fw-bold'} role="button" onClick={this.openModal}>Subir otro CV</a>
          </div>,
      });
    }

    this.setState({ apikey, cp: this.props.cp, fields: fields });
    this.setState({ mandatoryFields: { ...this.state.mandatoryFields, ...this.props.cp.fields } });
  }

  handleNext(newFields) {
    this.updateFields(newFields);
    const { currentStep } = this.state;
    this.saveAR();
    if (currentStep < this.state.numberOfSteps - 1) {
      const { sectionsAccessible } = this.state;
      sectionsAccessible[currentStep] = true;
      this.setState({ sectionsAccessible });
      this.setState({ currentStep: currentStep + 1, wasSaved: false });
    } else {
      this.submitAR();
    }
  }

  handleBack(newFields) {
    this.updateFields(newFields);
    const currentStep = this.state.currentStep;
    this.saveAR();
    if (currentStep === this.state.personalSectionStep) {
      window.location.href = `/applicant/home?apikey=${this.state.apikey}`;
    } else {
      this.setState({ currentStep: currentStep - 1 });
    }
  }

  openModal() {
    this.setState({ anotherCV: !this.state.anotherCV });
  }

  uploadNewCV(boolean) {
    if (boolean) {
      window.location.href = `/applicant/curricular_profiles/${this.props.cp.id}/upload_cv?apikey=${this.state.apikey}&ar_id=${this.props.arId}`;
    }
    this.setState({ anotherCV: !this.state.anotherCV });
  }

  updateFields(newFields) {
    this.setState({ fields: { ...this.state.fields, ...newFields } });
  }

  getStepState(stepNumber) {
    if (this.state.currentStep === stepNumber) return 'active';
    if (this.state.sectionsAccessible[stepNumber]) return 'available';

    return 'inactive';
  }

  handleTabChange(event) {
    const step = Number(event.target.dataset.step);
    const { sectionsAccessible } = this.state;
    if (step === this.state.currentStep || !sectionsAccessible[step]) return;
    for (let i = step + 1; i < this.state.numberOfSteps; i++) {
      sectionsAccessible[i] = false;
    }
    this.setState({ sectionsAccessible, currentStep: step });
  }

  // eslint-disable-next-line max-statements
  render() {
    const breadlist = [
      {
        label: 'Home',
        value: `${this.props.applicantPortal}/?apikey=${this.state.apikey}`,
      },
      {
        label: 'Subir CV',
        value: `/applicant/curricular_profiles/${this.state.cp.id}/upload_cv?apikey=${this.state.apikey}&ar_id=${this.props.arId}`,
      },
      {
        label: 'Información Currícular',
        value: `/applicant/curricular_profiles/${this.state.cp.id}?
                override=true&apikey=${this.state.apikey}`,
      },
    ];

    const anotherCV = this.state.anotherCV;
    let fileUploadModal;
    if (anotherCV) {
      fileUploadModal = 
        <Modal
          title={'Subir CV'}
          subtitle={'Al subir otro CV, se sobreescribirá el contenido actual de tu solicitud curricular'}
          backAction={'Cancelar'}
          nextAction={'Subir CV'}
          functionToHandle={this.uploadNewCV}
        />;
    }

    return (
      <div className={'cp-form'}>
        <TopBanner apikey={this.state.apikey}/>
        <div className="container-md">
          <KsBreadcrumb elements={breadlist}/>
          {this.state.currentStep === 0 && this.state.alertBox}
          {fileUploadModal}
          <div className="d-flex flex-wrap justify-content-between gap-3 my-3 my-md-4">
            <div className={'cp-form__header-left'}>
              <p className={'cp-form__title'}>Información Curricular</p>
              <p className={'cp-form__subtitle'}>Completa los campos obligatorios (*)</p>
            </div>
            {this.state.currentStep !== 0 && this.state.wasSaved && <SavedBox/> }
            {this.state.currentStep === 0 && this.state.fileNameBox}
          </div>

          <div className={'cp-form__saved'}>
            {this.state.currentStep === 0 && this.state.wasSaved && <SavedBox />}
          </div>

          <div className="d-flex flex-wrap gap-2">
            <div
              data-step={this.state.personalSectionStep}
              onClick={this.handleTabChange}
              role="button"
              tabIndex={0}
              className={`cp-form__tab cp-form__tab--${this.getStepState(this.state.personalSectionStep)} col`}
            >
              Personal
            </div>
            <div
              data-step={this.state.educationSectionStep}
              onClick={this.handleTabChange}
              role="button"
              tabIndex={0}
              className={`cp-form__tab cp-form__tab--${this.getStepState(this.state.educationSectionStep)} col`}
            >
              Educación
            </div>
            <div
              data-step={this.state.jobSectionStep}
              onClick={this.handleTabChange}
              role="button"
              tabIndex={0}
              className={`cp-form__tab cp-form__tab--${this.getStepState(this.state.jobSectionStep)} col`}
            >
              Laboral
            </div>
            <div
              data-step={this.state.otherSectionStep}
              onClick={this.handleTabChange}
              role="button"
              tabIndex={0}
              className={`cp-form__tab cp-form__tab--${this.getStepState(this.state.otherSectionStep)} col`}
            >
              Idiomas/Habilidades/Hobbies
            </div>
          </div>

          <div>
            { this.state.currentStep === this.state.personalSectionStep && (
              <PersonalSection
                fields={this.state.fields}
                mandatoryFields={['nombre_completo', 'email']}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                isAnswerable={this.props.isAnswerable}
              />
            )}
            { this.state.currentStep === this.state.educationSectionStep && (
              <EducationSection
                fields={this.state.fields}
                mandatoryFields={['pais', 'nivel_estudios', 'estado_estudios', 'institucion', 'fecha_inicio']}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                isAnswerable={this.props.isAnswerable}
              />
            )}
            { this.state.currentStep === this.state.jobSectionStep && (
              <JobSection
                fields={this.state.fields}
                mandatoryFields={['años_de_experiencia', 'cargo', 'empleador', 'pais', 'ciudad', 'fecha_inicio']}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                isAnswerable={this.props.isAnswerable}
              />
            )}
            { this.state.currentStep === this.state.otherSectionStep && (
              <OtherSection
                fields={this.state.fields}
                mandatoryFields={this.state.mandatoryFields}
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                isAnswerable={this.props.isAnswerable}
                updateFields ={this.updateFields}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CurricularProfileShow;
