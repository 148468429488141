/* eslint-disable react/prop-types */
import React from 'react';
import closeIcon from '../../../assets/images/close_icon.svg';

function Modal(props) {
  const funct = (bool) => {
    props.functionToHandle(bool, props);
  };

  return (
    <div className={'modal-background'}
    >
      <div className={'modal-container'}>
        <button
          className={'modal-container__close-icon'}
          onClick={ () => funct(false) }>
          <img src={closeIcon} alt="X"/>
        </button>
        <h3 className={'modal-container__title'}>{props.title}</h3>
        <p className={'modal-container__subtitle'}>{props.subtitle}</p>
        <div className={'modal-container__buttons d-flex gap-3'}>
          <button
            className={'modal-container__buttons--back'}
            onClick={ () => funct(false) }>{props.backAction}</button>
          <button
            className={'modal-container__buttons--next'}
            onClick={ () => funct(true) }>{props.nextAction}</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
