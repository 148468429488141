/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import IndexTable from '../containers/IndexTable';
import TopBanner from '../containers/TopBanner';
import Pagination from '../components/Common/Pagination';
import KsBreadcrumb from './Common/Breadcrumb';

const pageSize = 10;
class ApplicantHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: null,
      apikey: '',
      active_cps: [],
      answered_cps: [],
      showed_answered_cps: [],
      currentPage: 0,
      initialIndex: 1,
      totalCount: 1,
      totalPages: 0,
    };
  }

  componentDidMount() {
    const search = window.location.search;
    const apikey = (new URLSearchParams(search)).get('apikey');
    const success = (new URLSearchParams(search)).get('code');
    if (success === 'sent') {
      this.setState({ success: '¡Felicidades! Hemos enviado correctamente tu Carga Curricular.' });
    } else if (success === 'saved') {
      this.setState({ success: 'Se han guardado los datos de tu Carga Curricular pero aún no han sido enviados' });
    }
    this.setState({ apikey }, () => {
      this.setCps();
    });
  }

  paginate(page_number) {
    const array = this.state.answered_cps;

    return array.slice(page_number * pageSize, (page_number + 1) * pageSize);
  }

  handlePage(value) {
    const page_number = value.selected;
    const cps = this.paginate(page_number);
    this.setState({
      currentPage: page_number,
      initialIndex: page_number * pageSize + 1,
      showed_answered_cps: cps,
    });
  }

  forcePage() {
    console.log('Force Page');
  }

  setCps() {
    axios.get('/api/v1/applicant/curricular_profiles', { headers: { apikey: this.state.apikey } })
      .then(res => {
        const cps = res.data.curricular_profiles;
        const active_cps = cps.true;
        const answered_cps = cps.false;
        const totalCount = answered_cps.length;
        const totalPages = Math.ceil(totalCount / pageSize);
        const showed_answered_cps = answered_cps.slice(0, pageSize);
        this.setState({ active_cps, answered_cps, showed_answered_cps, totalCount, totalPages });
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  render() {
    const success = (
      this.state.success === null ?
        null :
        <div className={'alert alert-wide alert-success horizontal-center'} role="alert"> {this.state.success} </div>
    );

    return (
      <div>
        <TopBanner apikey={this.state.apikey} name={this.props.applicantName}/>
        <KsBreadcrumb elements={[{ label: 'Inicio', value: '' }]} />
        <div className={'vertical-container'}>
          <p className={'body-title'}>Solicitudes Curriculares</p>
          <p className={'body-descriptor'}>Revisa tus solicitudes activas y finalizadas</p>
          <div className={'vertical-center horizontal-center'}>
            <div className={'container'}>
              {success}
              <IndexTable
                title={'Solicitudes Activas'}
                cps={this.state.active_cps}
                apikey={this.state.apikey}
                inProgress={true}
              />
              <IndexTable
                title={'Solicitudes Finalizadas'}
                cps={this.state.showed_answered_cps}
                apikey={this.state.apikey}
              />
              { this.state.totalPages > 0 ?
                <div className="float-right">
                  <Pagination
                    initialIndex={this.state.initialIndex}
                    finalIndex={Math.min(this.state.initialIndex + pageSize - 1, this.state.totalCount)}
                    totalCount={this.state.totalCount}
                    pagesHandler={this.handlePage.bind(this)}
                    pagesCount={this.state.totalPages}
                    forcePage={this.props.forcePage}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    activeClassName={'active page-active page-active-green'}
                  />
                </div> :
                null }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicantHome;
