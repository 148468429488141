/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';

class PersonalSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.state = {
      // isValid: false,
      fields: {
        email: '',
        nombre_completo: '',
        redes_sociales: '',
        resumen: '',
        sitio_web: '',
      },
      mandatoryFields: {
        email: false,
        nombre_completo: false,
        redes_sociales: false,
        resumen: false,
        sitio_web: false,
      },
    };
  }

  componentDidMount() {
    const fields = { ...this.props.fields };
    const mandatoryFields = { ...this.props.mandatoryFields };

    this.setState({
      fields: {
        nombre_completo: fields.nombre_completo,
        email: fields.email,
        sitio_web: fields.sitio_web,
        redes_sociales: fields.redes_sociales,
        resumen: fields.resumen || '',
      },
      mandatoryFields: {
        nombre_completo: mandatoryFields.nombre_completo,
        email: mandatoryFields.email,
      },
    });
  }

  validateSection() {
    const mandatory = this.props.mandatoryFields;
    const missingFields = [];

    for (let index = 0; index < mandatory.length; index++) {
      const element = mandatory[index];
      if (this.state.fields[element].length < 1) missingFields.push(element);
    }

    if (missingFields.length > 0) {
      this.setState({ isValid: false });
    } else {
      this.setState({ isValid: true });
    }
  }

  handleInputChange(event) {
    const { value, name } = event.target;
    const fields = this.state.fields;

    switch (name) {
      case 'redes_sociales':
      case 'resumen':
      case 'sitio_web':
      default:
        fields[name] = value;
        break;
    }

    this.setState({ fields });
    // this.validateSection();
  }

  handleBack() {
    this.props.handleBack(this.state.fields);
  }

  handleNext() {
    this.props.handleNext(this.state.fields);
  }

  render() {
    const fields = this.state.fields;

    return (
      <>
        <div className={'vstack p-4 p-md-5 mt-3 bg-white shadow-sm gap-3'}>
          <div className={'row gap-3'}>
            <div className={'col-sm'}>
              <label className={'personal__label'}>Nombre y Apellido</label><strong className={'mandatory-field'}>(*)</strong>
              <input
                type="string"
                name="nombre_completo"
                className={'form-control cp-input'}
                placeholder={'Ingresa'}
                value={fields.nombre_completo}
                disabled={true}
              />
            </div>
            <div className={'col-sm'}>
              <label className={'personal__label'}>Email</label><strong className={'mandatory-field'}>(*)</strong>
              <input
                type="email"
                name="email"
                className={'form-control cp-input'}
                placeholder={'correo@correo.cl'}
                value={fields.email}
                disabled={true}
              />
            </div>
          </div>

          <div className={'row gap-3'}>
            <div className={'col-sm'}>
              <label className={'personal__label'}>Sitio Web</label>
              <input
                name="sitio_web"
                type="string"
                className={'form-control cp-input '}
                placeholder={'Ingresa URL del sitio'}
                onChange={this.handleInputChange}
                value={fields.sitio_web}
                disabled={!this.props.isAnswerable}
              />
            </div>
            <div className={'col-sm'}>
              <label className={'personal__label'}>Redes Sociales</label>
              <input
                name="redes_sociales"
                type="string"
                className={'form-control cp-input '}
                onChange={this.handleInputChange}
                value={fields.redes_sociales}
                placeholder={'Ingresa URL de red social'}
                disabled={!this.props.isAnswerable}
              />
              <small className={'personal__disclaimer'}>LinkedIn, Facebook, Instagram, etc.</small>
            </div>
          </div>

          <div className={'col-sm'}>
            <label className={'personal__label'}>Resumen</label>
            <textarea
              name="resumen"
              className={'personal__textarea form-control'}
              placeholder={'Cuéntanos sobre ti'}
              onChange={this.handleInputChange}
              value={fields.resumen}
              rows={6}
              disabled={!this.props.isAnswerable}
            />
          </div>
        </div>

        <div className={'d-flex justify-content-end mt-3'}>
          <button
            type="submit"
            className={'btn-form btn-form--inverse'}
            onClick={this.handleBack.bind(this)}
            disabled={!this.props.isAnswerable}
          >
            Volver
          </button>
          <button
            type="submit"
            className={'btn-form'}
            onClick={this.handleNext}
            disabled={!this.props.isAnswerable}
          >
            Siguiente
          </button>
        </div>
      </>
    );
  }
}

export default PersonalSection;
