import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const BreadcrumbContainer = (elements) => {

  const last = elements.pop();

  return (
    <Breadcrumb className="breadcrumb-ks mt-3">
      {elements.map((element, index) => {
        return( <Breadcrumb.Item key={index} href={element.value}>{element.label}</Breadcrumb.Item> )
      })}
      <Breadcrumb.Item active>{last.label}</Breadcrumb.Item>
    </ Breadcrumb>
  )
};

const KsBreadcrumb = (props) => {
  return (
    BreadcrumbContainer(props.elements)
  )
};

export default KsBreadcrumb;