/* eslint-disable max-statements */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import uuid from '../Common/uuid';
import errorHandler from '../Common/errorHandler';
import AdviceBox from '../Common/advice_box';
import Modal from '../Common/Modal';
import AddEducation from '../../../assets/images/add_icon_talana.png';
import EmptyStar from '../../../assets/images/star_outline_icon.png';
import FullStar from '../../../assets/images/star_full_icon.png';
import Trash from '../../../assets/images/trash_icon.png';
import LocationSelector from '../../containers/LocationSelector';

class EducationSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.ubicacion_institutoRef = [];
    this.nivelEstudiosRef = [];
    this.estadoEstudiosRef = [];
    this.institucionRef = [];
    this.descripcionRef = [];
    this.fechaInicioRef = [];
    this.otraInstitucionRef = [];
    this.state = {
      apiKey: '',
      fields: {
        educacion: [],
      },
      mandatoryFields: {
        educacion: false,
      },
      statusOptions: ['Titulado/Graduado', 'Egresado', 'Cursando', 'Incompleto'],
      levelOptions: ['Educación Básica/Preparatoria', 'Educación Media/Secundaria', 'Educación Técnica', 'Universitaria', 'Postítulo'],
      yearsGap: 70,
      initYears: [],
      endYears: [],
      openRemoveModal: false,
      modalElement: null,
      flagUncompleted: true,
    };
  }

  componentDidMount() {
    const search = window.location.search;
    const fields = { ...this.props.fields };
    const mandatoryFields = { ...this.props.mandatoryFields };
    if (fields.educacion.length === 0) {
      fields.educacion.push({
        uuid: uuid(),
        ubicacion_instituto: '',
        nivel_educacional: '',
        estado: '',
        nombre_instituto: '',
        otra_institucion: '',
        descripcion: '',
        fecha_inicio: '',
        fecha_termino: '',
        starred: true,
        universities: [],
      });
    }
    if (!fields.universities) {
      fields.universities = [];
    }
    this.setState({
      apiKey: (new URLSearchParams(search)).get('apikey'),
      fields: {
        educacion: fields.educacion,
        universities: fields.universities,
      },
      mandatoryFields: {
        ...mandatoryFields,
      },
      yearsGap: 70,
      initYears: [],
      endYears: [],
      openRemoveModal: false,
      modalElement: null,
      flagUncompleted: true,
    });
    this.setInitYears();
    this.setEndYears();
  }

  // Setea el país, y de ser del conjunto de educación, trae las universidades
  selectCountry(field, attribute, id, ubicacion_instituto) {
    // this, educacion, ubicacion_instituto , index
    const fields = this.state.fields;
    const newEducacion = this.state.fields.educacion.map(educacion => {
      if (educacion.uuid === id) return { ...educacion, ubicacion_instituto: ubicacion_instituto };

      return educacion;
    });

    fields['educacion'] = newEducacion;

    this.getUniversities(ubicacion_instituto, id);
    this.setState({ fields });
  }

  // Obtiene las universidades
  getUniversities(country_code, id) {
    axios.get('/api/v1/applicant/country_universities/', {
      params: { country_code },
      headers: { apikey: this.state.apiKey },
    })
      .then(res => {
        const universities = res.data.universities.map(a => a.name);
        universities.push('Otros');
        const fields = this.state.fields;
        const newEdu = fields.educacion.map(educacion => {
          if (educacion.uuid === id) return { ...educacion, universities };

          return educacion;
        });

        fields.educacion = newEdu;
        this.setState({ fields });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Cambia la fecha de un subconjunto
  selectDate(label, index, attr, date) {
    const fields = this.state.fields;
    fields[label][index][attr] = date;
    this.setState({ fields });
  }

  handleInputChange(value, name, index) {
    const fields = this.state.fields;
    if (name === 'nivel_educacional' || name === 'ubicacion_instituto') {
      fields.educacion[index] = {
        ...fields.educacion[index],
        nivel_educacional: value,
        estado: fields.educacion[index].estado ? fields.educacion[index].estado : '',
        nombre_instituto: fields.educacion[index].nombre_instituto ? fields.educacion[index].nombre_instituto : '',
        otra_institucion: fields.educacion[index].otra_institucion ? fields.educacion[index].otra_institucion : '',
        descripcion: fields.educacion[index].descripcion ? fields.educacion[index].descripcion : '',
        fecha_inicio: fields.educacion[index].fecha_inicio ? fields.educacion[index].fecha_inicio : '',
        fecha_termino: fields.educacion[index].fecha_termino ? fields.educacion[index].fecha_termino : '',
      };
      this.setState({ fields });

      return;
    }
    fields.educacion[index][name] = value;
    this.setState({ fields });
  }

  handleBack() {
    this.props.handleBack(this.state.fields);
  }

  handleNext() {
    this.props.handleNext(this.state.fields);
  }

  addEntry() {
    const fields = this.state.fields;

    fields['educacion'].push({
      uuid: uuid(),
      ubicacion_instituto: '',
      nivel_educacional: '',
      estado: '',
      nombre_instituto: '',
      otra_institucion: '',
      descripcion: '',
      fecha_inicio: '',
      fecha_termino: '',
      starred: false,
      universities: [],
    });
    this.setState({ fields });
  }
  validateInputs(nextAction) {
    let flag = true;
    this.state.fields.educacion.forEach((educacion, index) => {
      const nivelEstudiosP = document.querySelector(`#nivelEstudiosRef${index}`);
      const estadoEstudiosP = document.querySelector(`#estadoEstudiosRef${index}`);
      const institucionP = document.querySelector(`#institucionRef${index}`);
      const descripcionP = document.querySelector(`#descripcionRef${index}`);
      const fechaInicioP = document.querySelector(`#fechaInicioRef${index}`);
      const educationCareerDivs = document.querySelectorAll('.education__career');
      if (this.nivelEstudiosRef[index].value === '') {
        // refElement, marcar o no(true, false dependiendo), p tag para el mensaje de error
        errorHandler(this.nivelEstudiosRef[index], true, nivelEstudiosP);
        flag = false;
      } else {
        errorHandler(this.nivelEstudiosRef[index], false, nivelEstudiosP);
      }
      if (this.estadoEstudiosRef[index].value === '') {
        errorHandler(this.estadoEstudiosRef[index], true, estadoEstudiosP);
        flag = false;
      } else {
        errorHandler(this.estadoEstudiosRef[index], false, estadoEstudiosP);
      }
      if (this.institucionRef[index].value === '') {
        errorHandler(this.institucionRef[index], true, institucionP);
        flag = false;
      } else {
        errorHandler(this.institucionRef[index], false, institucionP);
      }
      if (this.fechaInicioRef[index].value === '') {
        errorHandler(this.fechaInicioRef[index], true, fechaInicioP);
        educationCareerDivs.forEach(div => {
          div.style.marginBottom = '20px';
        });
        flag = false;
      } else {
        errorHandler(this.fechaInicioRef[index], false, fechaInicioP);
        educationCareerDivs.forEach(div => {
          div.style.marginBottom = '0px';
        });
      }
      if (this.descripcionRef[index].value === '') {
        errorHandler(this.descripcionRef[index], true, descripcionP);
        flag = false;
        educationCareerDivs.forEach(div => {
          div.style.marginBottom = '0px';
        });
      } else {
        errorHandler(this.descripcionRef[index], false, descripcionP);
      }
      if (this.institucionRef[index].value === 'Otros' && this.otraInstitucionRef[index].value === '') {
        errorHandler(this.otraInstitucionRef[index], true, institucionP);
        flag = false;
      } else if (this.institucionRef[index].value === 'Otros' && this.otraInstitucionRef[index].value !== '') {
        errorHandler(this.otraInstitucionRef[index], false, institucionP);
      }
    });
    if (flag) {
      if (nextAction === 'addEntry') this.addEntry();
      if (nextAction === 'handleBack') this.handleBack();
      if (nextAction === 'handleNext') this.handleNext();
      this.setState({ flagUncompleted: true });
    } else {
      this.setState({ flagUncompleted: false });

      return;
    }
  }
  updateModal(educacion) {
    if (educacion.ubicacion_instituto === '' && 
      educacion.nivel_educacional === '' && educacion.estado === '' &&
      educacion.nombre_instituto === '' && educacion.otra_institucion === '' && educacion.descripcion === '' &&
      educacion.fecha_inicio === '' && educacion.fecha_termino === '') {
      this.confirmedRemoveEntry('educacion', educacion.uuid, educacion.starred);

      return;
    }
    let modalElement = this.state.modalElement;
    modalElement = 
      <Modal
        title={'Eliminar educación'}
        subtitle={
          `Al eliminar este contenido no podrás recuperar esta información.
          Recuerda que debes tener al menos un item de educación para avanzar.`
        }
        backAction={'Cancelar'}
        nextAction={'Eliminar'}
        functionToHandle={this.removeEntry}
        name='educacion'
        uuid={educacion.uuid}
        isStarred={educacion.starred}
      />;
    this.setState({ modalElement, openRemoveModal: true });
  }
  removeEntry(isOk, props) {
    if (isOk) {
      this.confirmedRemoveEntry(props.name, props.uuid, props.isStarred);
    } else {
      this.setState({ openRemoveModal: false });
    }
  }
  confirmedRemoveEntry(name, id, isStarred) {
    const fields = this.state.fields;
    const newEdu = fields.educacion.filter(educacion => educacion.uuid !== id);
    fields.educacion = newEdu;
    if (isStarred) fields.educacion[0].starred = true;
    this.setState({ fields, openRemoveModal: false });
  }

  addStar(uid, isStarred) {
    const fields = this.state.fields;
    const newEdu = fields.educacion.map(educacion => {
      if (educacion.uuid === uid) return { ...educacion, starred: true };

      return { ...educacion, starred: false };
    });
    fields.educacion = newEdu;
    this.setState({ fields });
  }

  setInitYears() {
    const currentYear = new Date().getFullYear();
    const dateArr = [];

    for (let i = currentYear - this.state.yearsGap; i < currentYear + 1; i++) {
      dateArr.unshift(i);
    }

    this.setState({ initYears: dateArr });
  }

  setEndYears() {
    const currentYear = new Date().getFullYear();
    const dateArr = [];
    const lessYears = 19;

    for (let i = currentYear - this.state.yearsGap; i < currentYear + this.state.yearsGap - lessYears; i++) {
      dateArr.unshift(i);
    }

    this.setState({ endYears: dateArr });
  }

  render() {
    const fields = this.state.fields;

    return (
      <>
        {fields.educacion && fields.educacion.map((educacion, index) => {
          return (
            <div className={'vstack p-4 p-md-5 mt-3 bg-white shadow-sm gap-3'} key={educacion.uuid}>
              { this.state.openRemoveModal && this.state.modalElement}

              { index < 1 && 
                <div className={'education__header row gap-3'}>
                  <div className={'col-sm-auto'}>
                    <h3 className={'education__title'}>Educación</h3>
                  </div>
                  <div className={'col-sm'}>
                    <AdviceBox messages={['Ingresa al menos una educación', 'Identifica tu experiencia más importante con la estrella']}></AdviceBox>
                  </div>
                </div>
              }

              <div className='vstack gap-3'>
                <div className="row gap-3">
                  <div className="col-sm">
                    <ReactTooltip place="top" type="dark" effect="solid" id="Star">
                      <span>Importante</span>
                    </ReactTooltip>
                    <button
                      className={'education__icons--button'}
                      data-tip
                      data-for="Star"
                      onClick={() => this.addStar(educacion.uuid, educacion.starred)}
                    >
                      <img id={'star'} src={educacion.starred ? FullStar : EmptyStar} alt="*"/>
                    </button>
                    { index > 0 && (
                      <button
                        className={'education__icons--button education__icons--trash'}
                        onClick={() => this.updateModal(educacion)}
                      >
                        <img src={Trash} alt="X"/>
                      </button>
                    )}
                  </div>
                </div>

                <div className={'education__first-row row gap-3'}>
                  <div className={'col-sm'}>
                    <label className={'personal__label'}>País</label><strong className={'mandatory-field'}>(*)</strong>
                    <LocationSelector
                      ref={(ref) => { this.ubicacion_institutoRef[index] = ref; }}
                      enableCountry={true}
                      enableRegion={false}
                      enableCity={false}
                      extraClass={'form-select cp-input'}
                      country={educacion.ubicacion_instituto}
                      selectCountry={this.selectCountry.bind(this, 'educacion', 'ubicacion_instituto', educacion.uuid)}
                      onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                      missingClass={() => false}
                      isItem={true} /* Esto dice si este selector tiene su propia fila o si comparte con otros */
                      isDisabled={!this.props.isAnswerable}
                    />
                  </div>
                  <div className={'col-sm'}>
                    <label className={'personal__label'}>Nivel estudios</label><strong className={'mandatory-field'}>(*)</strong>
                    <select
                      ref={(ref) => { this.nivelEstudiosRef[index] = ref; }}
                      value={educacion.nivel_educacional}
                      name="nivel_educacional"
                      className={'form-select cp-input'}
                      onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                      disabled={!this.props.isAnswerable}
                    >
                      <option value=''>Selecciona</option>
                      {this.state.levelOptions.map((level, i) => {
                        return <option key={i} value={level}>{level}</option>;
                      })}
                    </select>
                    <p id={`nivelEstudiosRef${index}`} className="error-message"> Selecciona nivel de estudios </p>
                  </div>
                </div>

                <div className={'row gap-3'}>
                  <div className={'col-sm'}>
                    <label className={'personal__label'}>Estado estudios</label><strong className={'mandatory-field'}>(*)</strong>
                    <select
                      ref={(ref) => { this.estadoEstudiosRef[index] = ref; }}
                      value={educacion.estado}
                      name="estado"
                      className={'form-select cp-input'}
                      onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                      disabled={!this.props.isAnswerable}
                    >
                      <option value=''>Selecciona</option>
                      { this.state.statusOptions.map((level, i) => {
                        return <option key={i} value={level}>{level}</option>;
                      })}
                    </select>
                    <p id={`estadoEstudiosRef${index}`} className="error-message"> Selecciona estado de estudios </p>
                  </div>

                  <div className={'col-sm'}>
                    <label className={'personal__label'}>Institución educativa</label><strong className={'mandatory-field'}>(*)</strong>
                    <select
                      ref={(ref) => { this.institucionRef[index] = ref; }}
                      value={educacion.nombre_instituto}
                      name="nombre_instituto"
                      className={'form-select cp-input'}
                      onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                      disabled={!this.props.isAnswerable}
                    >
                      <option value=''>Selecciona</option>
                      { educacion.nivel_educacional === 'Universitaria' && educacion.universities && educacion.universities.map((universidad, i) => {
                        if (universidad === 'Otros') return '';

                        return <option key={i} value={universidad}>{universidad}</option>;
                      })}
                      { educacion.nombre_instituto !== 'Universitaria' && (
                        <option value='Otros'>Otros</option>
                      )}
                    </select>
                    { this.state.fields.educacion[index].nombre_instituto === 'Otros' && 
                      <input
                        ref={(ref) => { this.otraInstitucionRef[index] = ref; }}
                        value={educacion.otra_institucion || ''}
                        type="text"
                        name="otra_institucion"
                        className={'education__other-inst form-control cp-input'}
                        placeholder={'Ingresa el nombre de la institución'}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        disabled={!this.props.isAnswerable}
                      />
                    }
                    <p id={`institucionRef${index}`} className="error-message"> Selecciona institución educativa </p>
                  </div>
                </div>

                <div className={'row gap-3'}>
                  <div className={'education__career col-sm'}>
                    <label className={'personal__label'}>Carrera o especialidad</label><strong className={'mandatory-field'}>(*)</strong>
                    <input
                      ref={(ref) => { this.descripcionRef[index] = ref; }}
                      type="text"
                      name="descripcion"
                      className={'form-control cp-input'}
                      placeholder={'Ej: Ingeniería Comercial'}
                      onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                      value={educacion.descripcion}
                      disabled={!this.props.isAnswerable}
                    />
                    <p id={`descripcionRef${index}`} className="error-message"> Selecciona descripcion o especialidad</p>
                  </div>
                  <div className={'col-sm'}>
                    <div className={'row gap-3 d-flex justify-content-between'}>
                      <div className='col-sm'>
                        <label className={'personal__label'}>Fecha inicio</label><strong className={'mandatory-field'}>(*)</strong>
                        <select
                          ref={(ref) => { this.fechaInicioRef[index] = ref; }}
                          name="fecha_inicio"
                          className={'form-select cp-input'}
                          value={educacion.fecha_inicio}
                          onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                          disabled={!this.props.isAnswerable}
                        >
                          <option value=''>Año</option>
                          { this.state.initYears.map((date, i) => {
                            return <option key={i} value={date}>{date}</option>;
                          })}
                        </select>
                        <p id={`fechaInicioRef${index}`} className="error-message"> Selecciona fecha de inicio </p>
                      </div>

                      { educacion.fecha_inicio && (
                        <div className='col-sm'>
                          <label className={'personal__label'}>Fecha término (estimado)</label>
                          <select
                            name="fecha_termino"
                            className={'form-select cp-input'}
                            value={educacion.fecha_termino}
                            onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                            disabled={!this.props.isAnswerable}
                          >
                            { this.state.endYears.map((date, i) => {
                              if (educacion.fecha_inicio && educacion.fecha_inicio <= date)
                                return <option key={i} value={date}>{date}</option>;

                              return null;
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className={'education__add'}>
          <button
            className={'education__add-button'}
            name="educacion"
            onClick={() => this.validateInputs('addEntry') }
          >
            <img src={AddEducation} alt="+"/>
            Agregar educación
          </button>
        </div>

        <div className={'d-flex justify-content-end mt-3'}>
          <button
            type="submit"
            className={'btn-form btn-form--inverse'}
            onClick={() => this.validateInputs('handleBack') }
            disabled={!this.props.isAnswerable}
          >
            Volver
          </button>
          <button
            type="submit"
            className={'btn-form'}
            onClick={() => this.validateInputs('handleNext') }
            disabled={!this.props.isAnswerable}
          >
            Siguiente
          </button>
        </div>
      </>
    );
  }
}

export default EducationSection;
