/* eslint-disable react/prop-types */
import React from 'react';

function Modal(props) {
  return (
    <div className={props.className}>
      <p className={'alert-box__message'}>{props.message} <span>{props.boldMessage}</span></p>
    </div>
  );
}

export default Modal;
