/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */

// Reloads page on browser back button

export default function reload() {
  window.addEventListener('pageshow', (event) => {
    const historyTraversal = event.persisted ||
                          (typeof window.performance !== 'undefined' &&
                                window.performance.navigation.type === 2);
    if (historyTraversal) {
      // Handle page restore.
      window.location.reload();
    }
  });
}
