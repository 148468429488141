/* eslint-disable react/prop-types */
import React from 'react';
import focoImg from '../../../assets/images/lightbulb_icon.png';

function AdviceBox(props) {
  const propsMessages = props.messages;
  const messages = [];

  for (const [index, value] of propsMessages.entries()) {
    if (propsMessages.length > 1) {
      messages.push(<p key={index} className={'ks-advice-text'}><span>•</span>{value}</p>);
    } else {
      messages.push(<p key={index} className={'ks-advice-text'}>{value}</p>);
    }
  }

  return (
    <div className={'floatercontainer'}>
      <div className={'ks-advice'}>
        <img src={focoImg} className={'foco-img'} alt="Foco"/>
        <div>{messages}</div>
      </div>
    </div>
  );
}

export default AdviceBox;
