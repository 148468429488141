import React from 'react';
import axios from 'axios';
import LogoTalanaBlanco from 'images/talana-logo-white.svg';
import UserIcon from 'images/user_icon.svg';

class TopBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
    };
  }

  componentDidMount() {
    if (this.props.apikey) {
      axios.get('/api/v1/applicant/name', { headers: { 'apikey': this.props.apikey } })
        .then(response => {
          this.setState({ nombre: response.data.data.name.split(' ')[0] });
        })
        .catch(error => { console.log(error); });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.apikey && this.props.apikey) {
      axios.get('/api/v1/applicant/name', { headers: { 'apikey': this.props.apikey } })
        .then(response => {
          this.setState({ nombre: response.data.data.name.split(' ')[0] });
        })
        .catch(error => { console.log(error); });
    }
  }

  profileHandler() {
    axios.get('/api/v1/applicant/profile_redirect',
      { headers: { 'apikey': this.props.apikey },
        params: { callback_url: window.location.href },
      })
      .then(response => {
        window.location.href = response.data.data.url;
      })
      .catch(error => { console.log(error); });
  }

  render() {
    const backUrl = `/applicant/home?apikey=${this.props.apikey}`;

    return (
      <div className={'top-banner mb-2'}>
        <img className={'top-banner__logo'} src={LogoTalanaBlanco}/>
        <span className={'top-banner__title d-none d-sm-block'}>Carga Curricular</span>
        <a className={'nav-link'} onClick={this.profileHandler.bind(this)} role="button">
          <img className="top-banner__user-icon" src={UserIcon}/>
          <span className="top-banner__user-name">{this.state.nombre}</span>
        </a>
      </div>
    );
  }
}

export default TopBanner;
