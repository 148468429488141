import React from "react"
import ReactPaginate from "react-paginate"

const Pagination = props => {

  return (
    <div>
      <div className="float-left">
        <span className="pagination-label">Finalizadas <strong>{props.initialIndex} - {props.finalIndex} </strong> de <strong>{props.totalCount}</strong> total</span>
      </div>
      { props.pagesCount > 1
        ? <div className="float-right">
            <ReactPaginate
              pageCount={props.pagesCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              forcePage={props.forcePage}
              previousLabel={props.previousLabel ? props.previousLabel : "Anterior"}
              nextLabel={props.nextLabel ? props.nextLabel : "Siguiente"}
              breakLabel={<a>...</a>}
              breakClassName={"break-me"}
              onPageChange={props.pagesHandler}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={props.activeClassName ? props.activeClassName : "active page-active"}
              disableInitialCallback={false}
            />
          </div>
        : null
      }    
    </div>
  )
}

export default Pagination;