/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable func-style */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import ImgTooltipInfo from '../components/Common/ImgTooltip';
import ImgPencil from '../components/Common/ImgPencil';

class IndexTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const apikey = this.props.apikey;
    const url = (cp) => {
      return (`/applicant/curricular_profiles/${cp.id}?apikey=${apikey}&ar_id=${cp.ar_id}`);
    };
    const getCpState = (cp) => {
      return (
        <td className="center-text-td">
          <span className={`row-${cp.state.toLowerCase().replace(' ', '-')}`}>{cp.state}</span>
        </td>
      );
    };
    const cpRow = this.props.cps.map((cp) => {
      const cpDate = new Date(cp.assigned_at);

      return (<tr key={cp.ar_id}>
        <td className="center-text-td">{cp.kc_company_name}</td>
        <td className="center-text-td">{moment(cpDate).format('DD/MM/YYYY')}</td>
        <td className="center-text-td">{moment(cpDate).add(cp.validity_hours, 'hours').format('DD/MM/YYYY')}</td>
        {getCpState(cp)}
        { this.props.inProgress ?
          <td className="center-text-td text-center">
            <a className="no-decoration" href={url(cp)}>
              {cp.state === 'Pendiente' ?
                <span className="action-pendiente">Cargar CV</span> :
                <ImgPencil />}
            </a>
          </td> :
          null
        }
      </tr>
      );
    });

    return (
      <div className={'index-table'}>
        <h3>{this.props.title}</h3>
        {(this.props.cps === undefined || this.props.cps.length === 0) ?
          <div>
            <p>No hay consultas curriculares que mostrar.</p>
          </div> :
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th className="padding-header" scope="col">Organización</th>
                <th className="padding-header" scope="col">Fecha Solicitud</th>
                <th className="padding-header" scope="col">Fecha Límite
                  <a data-tip data-for="fechaLimite"><span><ImgTooltipInfo /></span></a>
                </th>
                <th scope="col" className="state-header padding-header">Estado</th>
                { this.props.inProgress ?
                  <th className="padding-header" scope="col"></th> :
                  null }
              </tr>
            </thead>
            <tbody>
              {cpRow}
            </tbody>
            <ReactTooltip place="top" type="dark" effect="solid" id="fechaLimite" className="date-limit-tooltip" >
              <span>La fecha límite es el plazo que tienes <br /> para cargar tu CV</span>
            </ReactTooltip>
          </table>}
      </div>
    );
  }
}

export default IndexTable;
