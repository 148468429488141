/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { faMinusSquare, faPlusSquare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LocationSelector from '../containers/LocationSelector';
import AdviceBox from '../components/Common/advice_box';
import SimpleInputSection from '../containers/SimpleInputSection';
import TopBanner from '../containers/TopBanner';
import KsBreadcrumb from './Common/Breadcrumb';

class CurricularProfileShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      error: '',
      success: null,
      sending: false,
      apikey: '',
      elementIdCounter: 0,
      cp: {},
      // Por cada campo de educación se abre un elemento aquí (universities),
      // y ese elemento tiene todas las UES del país correspondiente.
      universities: [],
      fields: {
        años_de_experiencia: '',
        educacion: [],
        email: '',
        experiencias_laborales: [],
        habilidades_ti: [],
        hobbies: [],
        lenguajes: [],
        nombre_completo: '',
        otras_habilidades: [],
        redes_sociales: '',
        resumen: '',
        sitio_web: '',
      },
      missing_fields: { /* Aqui se marcan los campos requeridos por la compañía que no están presentes. */
        años_de_experiencia: false,
        educacion: false,
        email: false,
        experiencias_laborales: false,
        habilidades_ti: false,
        hobbies: false,
        lenguajes: false,
        nombre_completo: false,
        otras_habilidades: false,
        redes_sociales: false,
        resumen: false,
        sitio_web: false,
      },
      missing_starred: {
        educacion: '',
        experiencias_laborales: '',
      },
      incompleted_error: {
        educacion: false,
        experiencias_laborales: false,
        habilidades_ti: false,
        hobbies: false,
        lenguajes: false,
        otras_habilidades: false,
      },
      incompleted_message: 'Falta completar algunos campos obligatorios',
    };
  }
  // Obtiene las universidades
  getUniversities(country_code, index) {
    axios.get('/api/v1/applicant/country_universities/', {
      params: { country_code },
      headers: { apikey: this.state.apikey },
    })
      .then(res => {
        const state = this.state;
        const universities = res.data.universities.map(a => a.name);
        state.universities[index] = universities;
        this.setState({ state });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        console.log(err);
      });
  }
  // Le agrega una estrella al subconjunto de datos (state)
  setStarred(field, index) {
    if (!this.props.is_answerable) {
      return;
    }
    const fields = this.state.fields;
    fields[field].forEach((x, i) => {
      x.starred = (i === index ? !x.starred : false);
    });
    this.setState({ fields });
  }
  // Le agrega una estrella al subconjunto de datos (estilo css)
  starredClass(field, index) {
    const fields = this.state.fields;

    return (fields[field][index].starred ? 'starred-item' : null);
  }
  // Le agrega borde rojo si es que el campo está vacío (estilo css)
  missingClass(field) {
    const missing_fields = this.state.missing_fields;

    return (missing_fields[field] === true ? 'missing-item' : null);
  }
  // Agrega o elimina un item del state
  // eslint-disable-next-line max-statements
  handleItemClick(item, direction, index = null, defaultValue = null) {
    const state = this.state;
    const fields = this.state.fields;
    const isValid = this.state.isValid;
    if (state.missing_fields[item] === true) {
      state.missing_fields[item] = false;
    }
    if (direction === 1) {
      const new_id = `${item}${this.state.elementIdCounter}`;
      this.setState({ elementIdCounter: this.state.elementIdCounter + 1 });
      if (item === 'educacion') {
        state.fields[item].push({ starred: false, id: new_id });
        state.universities.push([]);
        state.missing_fields['educacion'] = fields['educacion'].length === 0;
      } else if (defaultValue && item === 'lenguajes') {
        state.fields[item].push({ id: new_id, dominio: defaultValue[0] });
      } else {
        state.fields[item].push({ id: new_id });
      }
    } else {
      state.fields[item].splice(index, 1);
      if (item === 'educacion') {
        state.universities.pop();
        state.missing_fields['educacion'] = fields['educacion'].length === 0;
      }
    }
    this.setState({ state, isValid });
  }
  // Setea los valores de información básica y chequea si es que están presentes (solo si es requerido)
  handleChange(field, required, event) {
    const fields = this.state.fields;
    const missing_fields = this.state.missing_fields;
    let isValid = this.state.isValid;
    if (required) {
      missing_fields[field] = event.target.value === '';
      isValid = event.target.value === '';
    }

    if (field === 'años_de_experiencia') {
      fields[field] = this.validatePositiveNumber(event.target.value);
    } else {
      fields[field] = event.target.value;
    }

    this.setState({ fields, missing_fields, isValid });
  }
  // Guarda el nombre de la universidad escogida para una educación específica en el state
  selectUniversity(index, target) {
    const fields = this.state.fields;
    fields.educacion[index].nombre_instituto = target.value;
    this.setState({ fields });
  }
  // Setea el país, y de ser del conjunto de educación, trae las universidades
  selectCountry(field, attribute, index, pais) {
    const fields = this.state.fields;
    if (field === null) {
      fields.pais = pais;
    } else {
      fields[field][index][attribute] = pais;
      if (attribute === 'ubicacion_instituto') {
        this.getUniversities(pais, index);
      }
    }
    this.setState({ fields });
  }
  // Cambia los valores de los subconjuntos
  handleIndexedChange(field, attribute, index, event) {
    const fields = this.state.fields;
    const missing_fields = this.state.missing_fields;
    const isValid = this.state.isValid;
    const positiveNumber = this.validatePositiveNumber(event.target.value);
    const value = attribute === 'años_experiencia' ? positiveNumber : event.target.value;
    fields[field][index][attribute] = value;

    missing_fields['educacion'] = (field === 'educacion' && fields['educacion'].length === 0);
    this.setState({ fields, isValid });
  }
  // Cambia la fecha de un subconjunto
  selectDate(label, index, attr, date) {
    const fields = this.state.fields;
    fields[label][index][attr] = date;
    this.setState({ fields });
  }

  handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0);
    this.submitAR();
  }

  handleUploadAnother() {
    window.location.href = `/applicant/curricular_profiles/${this.props.cp.id}/
                            upload_cv?apikey=${this.state.apikey}&ar_id=${this.props.ar_id}`;
  }

  handleBack() {
    window.location.href = `/applicant/home?apikey=${this.state.apikey}`;
  }

  checkMissingValues(errorMessage) {
    const missing_fields = this.state.missing_fields;
    const incompleted_error = this.state.incompleted_error;
    const values = errorMessage.split(': ')[1].split(', ');
    const dictionary = {
      'Educación': 'educacion',
      'Experiencia Laboral': 'experiencias_laborales',
      'Habilidades TI': 'habilidades_ti',
      'Idiomas': 'lenguajes',
      'Otras Habilidades': 'otras_habilidades',
      'Hobbies': 'hobbies',
    };
    values.forEach((value) => {
      missing_fields[dictionary[value]] = true;
      incompleted_error[dictionary[value]] = true;
    });
    this.setState({ missing_fields, incompleted_error });
  }

  cleanState(missing_fields, missing_starred, incompleted_error) {
    Object.keys(missing_fields).forEach(k => {
      missing_fields[k] = false;
    });
    Object.keys(missing_starred).forEach(k => {
      missing_starred[k] = '';
    });
    Object.keys(incompleted_error).forEach(k => {
      incompleted_error[k] = false;
    });
  }

  submitAR() {
    const okStatus = 200;
    this.setState({ error: null, success: null, sending: true }, () => {
      if (this.state.fields['educacion'].length > 0) {
        axios.patch(`/api/v1/applicant/curricular_profiles/${this.props.cp.id}/responses/${this.props.ar_id}`, {
          response: { fields: this.state.fields },
        }, {
          headers: { apikey: this.state.apikey },
        })
          .then(res => {
            if (res.status === okStatus) {
              this.setState({ sending: false });
              window.location.href = `/applicant/home?code=sent&apikey=${this.state.apikey}`;
            }
          })
          // eslint-disable-next-line max-statements
          .catch(error => {
            const missing_fields = this.state.missing_fields;
            const missing_starred = this.state.missing_starred;
            const incompleted_error = this.state.incompleted_error;
            this.cleanState(missing_fields, missing_starred, incompleted_error);
            if (error.response.data.messages.hasOwnProperty('missing_fields')) {
              error.response.data.messages.missing_fields.forEach(e => {
                missing_fields[e] = true;
              });
              this.setState({
                error: 'No has completado los campos mínimos requeridos para este proceso de postulación. Por favor, completa los campos faltantes.',
                missing_fields,
              });
            }
            // Si es que faltó poner estrellas en educación o exp. laboral
            if (error.response.data.messages.missing_starred) {
              const dictionary = {
                'educacion': 'Educación',
                'experiencias_laborales': 'Experiencias Laborales',
              };
              Object.keys(dictionary).forEach((value) => {
                if (error.response.data.messages.missing_starred.includes(value)) {
                  missing_starred[value] = 'Debes seleccionar una experiencia como la más importante presionando la estrella.';
                } else {
                  missing_starred[value] = '';
                }

                missing_fields[value] = error.response.data.messages.missing_starred.includes(value);
              });
              this.setState({ error: 'Debes completar todos los campos obligatorios.' });
            }
            // Si es que hay campos vacíos
            if (error.response.data.messages.hasOwnProperty('incomplete_compound')) {
              this.checkMissingValues(error.response.data.messages.incomplete_compound);
              this.setState({ error: 'Debes completar todos los campos obligatorios.' });
            }
            if (
              !(error.response.data.messages.hasOwnProperty('incomplete_compound')) &&
              !(error.response.data.messages.missing_starred) &&
              !(error.response.data.messages.hasOwnProperty('missing_fields'))
            ) {
              this.setState({
                error: 'Ha ocurrido un error en el proceso de guardado. Por favor, intentalo más tarde.',
              });
            }
            this.setState({ sending: false });
          });
      } else {
        const missing_fields = this.state.missing_fields;
        missing_fields['educacion'] = true;
        this.setState({
          error: 'Debes agregar al menos una experiencia en Educación.',
          missing_fields: missing_fields,
          sending: false,
        });
      }
    });
  }

  saveAR(e) {
    const okStatus = 200;
    e.preventDefault();
    this.setState({ error: null, success: null, sending: true }, () => {
      axios.patch(`/api/v1/applicant/curricular_profiles/${this.props.cp.id}/responses/${this.props.ar_id}/save`, {
        response: { fields: this.state.fields },
      }, {
        headers: { apikey: this.state.apikey },
      })
        .then(res => {
          if (res.status === okStatus) {
            this.setState({ sending: false });
            window.location.href = `/applicant/home?code=saved&apikey=${this.state.apikey}`;
          }
        })
        .catch(() => {
          this.setState({ error: 'Ha ocurrido un error en el proceso de guardado. Por favor, intentalo más tarde.' });
          this.setState({ sending: false });
        });
      window.scrollTo(0, 0);
    });
  }

  componentDidMount() {
    const search = window.location.search;
    let isValid = false;
    const apikey = (new URLSearchParams(search)).get('apikey');
    const success = (new URLSearchParams(search))
      .get('code') === 'success' ?
      'Hemos procesado correctamente tu Carga Curricular. Por favor, confirma tus datos antes de guardarlos definitivamente.' :
      null;
    const error = (
      this.props.is_answerable === true ?
        null :
        'Ya respondiste esta Carga Curricular, por lo que no puedes editar tu respuesta.'
    );
    const universities = new Array(this.props.fields.educacion.length);
    isValid = this.props.fields['años_de_experiencia'] === '';
    this.setState({ apikey, success, cp: this.props.cp, fields: this.props.fields, error, universities, isValid },
      () => {
        this.props.fields.educacion.forEach((e, i) => {
          if (['Universitaria', 'Postítulo'].includes(e.nivel_educacional)) {
            this.getUniversities(e.ubicacion_instituto, i);
          }
        });
      },
    );
  }

  validatePositiveNumber(number) {
    let output = number;
    if (number < 0) {
      output = 0;
    } else {
      output = null;
    }

    return output;
  }

  render() {
    const fields = this.state.fields;
    const error = (
      this.state.error === null ?
        null :
        <div className={'alert alert-wide alert-danger horizontal-center'} role="alert"> {this.state.error} </div>
    );
    const success = (
      this.state.success === null ?
        null :
        <div className={'alert alert-wide alert-success horizontal-center'} role="alert"> {this.state.success} </div>
    );
    const breadlist = [
      {
        label: 'Home',
        value: `/applicant/home?apikey=${this.state.apikey}`,
      },
      {
        label: 'Subir CV',
        value: `/applicant/curricular_profiles/${this.state.cp.id}/upload_cv?
                apikey=${this.state.apikey}&ar_id=${this.props.ar_id}`,
      },
      {
        label: 'Información Currícular',
        value: `/applicant/curricular_profiles/${this.state.cp.id}?
                override=true&apikey=${this.state.apikey}`,
      },
    ];

    return (
      <div className={'cp-form'}>
        <TopBanner apikey={this.state.apikey}/>
        <KsBreadcrumb elements={breadlist}/>
        <p className={'body-title'}>Información Curricular</p>
        <p className={'body-descriptor'}>{this.props.filePresent ? 'Hemos vaciado la información de tu CV. Revisa los datos antes de confirmar el envío.' : 'Completa la información solicitada y luego confirma el envío' }</p>
        { success }
        { error }
        <div className={'container'}>
          <h2>Información Básica</h2>
          <div className={'profile-form-box form-column'}>
            <h3>Personal y Contacto</h3>

            <div className={'form-row'}>
              <div className={'item row-item'}>
                <label>Nombre Completo</label><strong className={'mandatory-field'}>*</strong>
                <input
                  type="string"
                  className={'form-control cp-input'}
                  placeholder={'Ingresa Nombre y Apellido'}
                  value={fields.nombre_completo} disabled={true}
                />
              </div>
              <div className={'item row-item'}>
                <label>Email</label><strong className={'mandatory-field'}>*</strong>
                <input
                  type="email"
                  className={'form-control cp-input'}
                  placeholder={'correo@correo.com'}
                  value={fields.email}
                  disabled={true}
                />
              </div>
            </div>

            <div className={'form-row'}>
              <div className={'item row-item'}>
                <label>Años de Experiencia</label><strong className={'mandatory-field'}>*</strong>
                <input
                  className={`form-control cp-input ${this.missingClass('años_de_experiencia')}`}
                  placeholder={'Ingresa Años Laborales'}
                  type="number"
                  onChange={this.handleChange.bind(this, 'años_de_experiencia', true)}
                  value={fields.años_de_experiencia}
                  disabled={!this.props.is_answerable}
                />
              </div>
              <div className={'item row-item'}>
                <label>Redes Sociales</label>
                <input
                  type="string"
                  className={'form-control cp-input '}
                  onChange={this.handleChange.bind(this, 'redes_sociales', false)}
                  value={fields.redes_sociales}
                  placeholder={'Facebook, LinkedIn, etc...'}
                  disabled={!this.props.is_answerable}
                />
              </div>
            </div>

            <div className={'form-row'}>
              <div className={'item row-item'}>
                <label>Sitio Web</label>
                <input
                  type="string"
                  className={'form-control cp-input '}
                  placeholder={'www.nombredetusitio.cl'}
                  onChange={this.handleChange.bind(this, 'sitio_web', false)}
                  value={fields.sitio_web}
                  disabled={!this.props.is_answerable}
                />
              </div>
            </div>

            <h3>Resumen</h3>
            <textarea
              className={'form-control'}
              placeholder={'Escribe una pequeña reseña sobre ti.'}
              onChange={this.handleChange.bind(this, 'resumen', false)}
              value={fields.resumen}
              rows={'6'}
              disabled={!this.props.is_answerable}
            />
          </div>

          <h2>Educación</h2>
          {this.state.incompleted_error['educacion'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> { this.state.incompleted_message } </div> :
            null}
          {this.state.missing_starred['educacion'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> {this.state.missing_starred['educacion']} </div> :
            null}
          <div className={`profile-form-box form-column ${this.missingClass('educacion')}`}>
            <AdviceBox message={'Ingresa al menos una institución de educación e identifica tu experiencia más importante presionando la estrella.'}></AdviceBox>
            { this.props.is_answerable ?
              <div className={'form-item-header-buttons'}>
                <FontAwesomeIcon
                  onClick={this.handleItemClick.bind(this, 'educacion', 1)}
                  className={'form-item-header-button'}
                  icon={faPlusSquare}
                />
              </div> :
              null }
            { [...fields.educacion].map((e, i) => {
              return (
                <div key={e.id} className={this.missingClass('educacion')}>
                  <div className={'form-row'}>
                    <FontAwesomeIcon
                      onClick={this.setStarred.bind(this, 'educacion', i)}
                      className={`form-item-header-starred ${this.starredClass('educacion', i)} ${(this.props.is_answerable ? '' : ' unclickable')}` }
                      icon={faStar}
                    />
                    <FontAwesomeIcon
                      onClick={this.handleItemClick.bind(this, 'educacion', -1, i)}
                      className={'form-item-header-button'}
                      icon={faMinusSquare}
                    />
                  </div>
                  <div className={'form-row'}>
                    <LocationSelector
                      enableCountry={true}
                      enableRegion={false}
                      enableCity={false}
                      countryLabel={'País de la Institución'}
                      extraClass={'item row-item cp-input'}
                      country={fields.educacion[i].ubicacion_instituto}
                      selectCountry={this.selectCountry.bind(this, 'educacion', 'ubicacion_instituto', i)}
                      missingClass={() => false}
                      isItem={true} /* Esto dice si este selector tiene su propia fila o si comparte con otros */
                      isDisabled={!this.props.is_answerable}
                    />
                    <div className={'item row-item'}>
                      <label>Nivel de Estudios</label><strong className={'mandatory-field'}>*</strong>
                      <select
                        className={'form-control cp-input'}
                        value={e.nivel_educacional}
                        onChange={this.handleIndexedChange.bind(this, 'educacion', 'nivel_educacional', i)}
                        disabled={!this.props.is_answerable}
                      >
                        <option></option>
                        <option>Educación Básica/Preparatoria</option>
                        <option>Educación Media/Secundaria</option>
                        <option>Educación Técnica</option>
                        <option>Universitaria</option>
                        <option>Postítulo</option>
                      </select>
                    </div>
                    <div className={'item row-item'}>
                      <label>Estado Estudio</label><strong className={'mandatory-field'}>*</strong>
                      <select
                        className={'form-control cp-input'}
                        value={e.estado}
                        onChange={this.handleIndexedChange.bind(this, 'educacion', 'estado', i)}
                        disabled={!this.props.is_answerable}
                      >
                        <option></option>
                        <option>Titulado/Graduado</option>
                        <option>Egresado</option>
                        <option>Cursando</option>
                        <option>Incompleto</option>
                      </select>
                    </div>
                  </div>
                  <div className={'form-row'}>
                    <div className={'item row-item'}>
                      <label>Nombre de la Institución</label><strong className={'mandatory-field'}>*</strong>
                      {
                        ['Universitaria', 'Postítulo'].includes(e.nivel_educacional) ?
                          <Select
                            // eslint-disable-next-line no-shadow
                            options={this.state.universities[i] ? this.state.universities[i].map(e => {
                              return ({ value: e, label: e });
                            }) : null}
                            value={{ value: e.nombre_instituto, label: e.nombre_instituto }}
                            onChange={this.selectUniversity.bind(this, i)}
                            isDisabled={!this.props.is_answerable}
                          /> :
                          <input
                            type="string"
                            placeholder={'Ej: Instituto Profesional Abadía'}
                            onChange={this.handleIndexedChange.bind(this, 'educacion', 'nombre_instituto', i)}
                            value={e.nombre_instituto}
                            className={'form-control cp-input'}
                            disabled={!this.props.is_answerable}
                          />
                      }
                    </div>
                    <div className={'item row-item'}>
                      <label>Fecha Inicio</label><strong className={'mandatory-field'}>*</strong>
                      <DatePicker
                        dropdownMode="select"
                        showYearDropdown
                        scrollableYearDropdown
                        className={'form-control cp-input'}
                        onChange={this.selectDate.bind(this, 'educacion', i, 'fecha_inicio')}
                        selected={Date.parse(e.fecha_inicio)}
                        dateFormat={'dd/MM/yyyy'}
                        disabled={!this.props.is_answerable}
                      />
                    </div>
                    <div className={'item row-item'}>
                      <label>Fecha Término</label>
                      <DatePicker
                        dropdownMode="select"
                        showYearDropdown
                        scrollableYearDropdown
                        className={'form-control cp-input'}
                        onChange={this.selectDate.bind(this, 'educacion', i, 'fecha_termino')}
                        selected={Date.parse(e.fecha_termino)}
                        dateFormat={'dd/MM/yyyy'}
                        disabled={!this.props.is_answerable}
                      />
                    </div>
                  </div>
                  <label>Descripción</label>
                  <textarea
                    className={'form-control'}
                    placeholder={'Describe tu experiencia de aprendizaje'}
                    onChange={this.handleIndexedChange.bind(this, 'educacion', 'descripcion', i)}
                    value={e.descripcion}
                    rows={'3'}
                    disabled={!this.props.is_answerable}
                  />
                  <hr/>
                </div>
              );
            },
            )}
          </div>

          <h2>Experiencia Laboral</h2>
          {this.state.incompleted_error['experiencias_laborales'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> { this.state.incompleted_message } </div> :
            null}
          {this.state.missing_starred['experiencias_laborales'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> {this.state.missing_starred['experiencias_laborales']} </div> :
            null}
          <div className={`profile-form-box form-column ${this.missingClass('experiencias_laborales')}` }>
            <AdviceBox
              message={'Identifica tu experiencia laboral más importante presionando la estrella. Recuerda que cada vez que ingreses una nueva experiencia, esta debe contener los campos obligatorios.'}>
            </AdviceBox>
            { this.props.is_answerable ?
              <div className={'form-item-header-buttons'}>
                <FontAwesomeIcon
                  onClick={this.handleItemClick.bind(this, 'experiencias_laborales', 1)}
                  className={'form-item-header-button'}
                  icon={faPlusSquare}
                />
              </div> :
              null }
            { [...fields.experiencias_laborales].map((e, i) => {
              return (
                <div key={e.id} className={this.missingClass('experiencias_laborales')}>
                  <div className={'form-row'}>
                    <FontAwesomeIcon
                      onClick={this.setStarred.bind(this, 'experiencias_laborales', i)}
                      className={`form-item-header-starred ${this.starredClass('experiencias_laborales', i)} ${this.props.is_answerable ? '' : ' unclickable'}` }
                      icon={faStar}/>
                    <FontAwesomeIcon
                      onClick={this.handleItemClick.bind(this, 'experiencias_laborales', -1, i)}
                      className={'form-item-header-button'}
                      icon={faMinusSquare}
                    />
                  </div>
                  <div className={'form-row'}>
                    <div className={'item row-item'}>
                      <label>Cargo</label><strong className={'mandatory-field'}>*</strong>
                      <input
                        type="string"
                        placeholder={'Ej. Ejecutivo de Ventas'}
                        onChange={this.handleIndexedChange.bind(this, 'experiencias_laborales', 'nombre_experiencia', i)}
                        value={e.nombre_experiencia}
                        className={'form-control cp-input'}
                        disabled={!this.props.is_answerable}
                      />
                    </div>
                    <div className={'item row-item'}>
                      <label>Empleador</label><strong className={'mandatory-field'}>*</strong>
                      <input
                        type="string"
                        placeholder={'Ej. KeyClouding'}
                        onChange={this.handleIndexedChange.bind(this, 'experiencias_laborales', 'empleador', i)}
                        value={e.empleador}
                        className={'form-control cp-input'}
                        disabled={!this.props.is_answerable}
                      />
                    </div>
                  </div>

                  <LocationSelector
                    enableCountry={true}
                    enableRegion={false}
                    enableCity={true}
                    countryLabel={'País'}
                    cityLabel={'Ciudad'}
                    extraClass={'item row-item cp-input'}
                    country={e.pais_experiencia}
                    city={e.ciudad_experiencia}
                    selectCountry={this.selectCountry.bind(this, 'experiencias_laborales', 'pais_experiencia', i)}
                    changeCity={this.handleIndexedChange.bind(this, 'experiencias_laborales', 'ciudad_experiencia', i)}
                    missingClass={() => false }
                    isItem={false}
                    isDisabled={!this.props.is_answerable}
                  />

                  <div className={'form-row'}>
                    <div className={'item row-item'}>
                      <label>Fecha Inicio</label><strong className={'mandatory-field'}>*</strong>
                      <DatePicker
                        dropdownMode="select"
                        showYearDropdown
                        scrollableYearDropdown
                        className={'form-control cp-input'}
                        onChange={this.selectDate.bind(this, 'experiencias_laborales', i, 'fecha_inicio')}
                        selected={Date.parse(e.fecha_inicio)}
                        dateFormat={'dd/MM/yyyy'}
                        disabled={!this.props.is_answerable}
                      />
                    </div>
                    <div className={'item row-item'}>
                      <label>Fecha Término</label>
                      <DatePicker
                        dropdownMode="select"
                        showYearDropdown
                        scrollableYearDropdown
                        className={'form-control cp-input'}
                        onChange={this.selectDate.bind(this, 'experiencias_laborales', i, 'fecha_termino')}
                        selected={Date.parse(e.fecha_termino)}
                        dateFormat={'dd/MM/yyyy'}
                        disabled={!this.props.is_answerable}
                      />
                    </div>
                  </div>
                  <label>Descripción</label>
                  <textarea
                    className={'form-control'}
                    placeholder={'Cuéntanos que hiciste en esta organización.'}
                    onChange={this.handleIndexedChange.bind(this, 'experiencias_laborales', 'descripcion', i)}
                    value={e.descripcion}
                    rows={'6'}
                    disabled={!this.props.is_answerable}
                  />
                  <hr/>
                </div>
              );
            })}
          </div>

          <h2>Idiomas</h2>
          {this.state.incompleted_error['lenguajes'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> { this.state.incompleted_message } </div> :
            null}
          <div className={`profile-form-box form-column ${this.missingClass('lenguajes')}` }>
            <SimpleInputSection
              handleItemClick={this.handleItemClick.bind(this, 'lenguajes')}
              items={fields.lenguajes}
              missingClass={this.missingClass.bind(this, 'lenguajes')}
              setStarred={this.setStarred.bind(this, 'lenguajes')}
              starredClass={this.starredClass.bind(this, 'lenguajes')}
              firstField={'Idioma'}
              firstFieldAccessor={'idioma'}
              firstPlaceHolder={'Ej. Español'}
              handleFirstFieldChange={this.handleIndexedChange.bind(this, 'lenguajes', 'idioma')}
              secondField={'Dominio'}
              secondFieldAccessor={'dominio'}
              secondPlaceHolder={'Ej. Medio'}
              secondFieldType={'select'}
              handleSecondFieldChange={this.handleIndexedChange.bind(this, 'lenguajes', 'dominio')}
              secondFieldOptions={['Básico', 'Medio', 'Avanzado', 'Nativo']}
              isDisabled={!this.props.is_answerable}
              hasStar={false}
            />
          </div>

          <h2>Habilidades TI</h2>
          {this.state.incompleted_error['habilidades_ti'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> { this.state.incompleted_message } </div> :
            null}
          <div className={`profile-form-box form-column ${this.missingClass('habilidades_ti')}` }>
            <SimpleInputSection
              handleItemClick={this.handleItemClick.bind(this, 'habilidades_ti')}
              items={fields.habilidades_ti}
              missingClass={this.missingClass.bind(this, 'habilidades_ti')}
              setStarred={this.setStarred.bind(this, 'habilidades_ti')}
              starredClass={this.starredClass.bind(this, 'habilidades_ti')}
              firstField={'Nombre'}
              firstFieldAccessor={'nombre'}
              firstPlaceHolder={'Ej. Ruby'}
              handleFirstFieldChange={this.handleIndexedChange.bind(this, 'habilidades_ti', 'nombre')}
              secondField={'Experiencia (en Años)'}
              secondFieldAccessor={'años_experiencia'}
              secondPlaceHolder={'En Años'}
              secondFieldType={'numeric'}
              handleSecondFieldChange={this.handleIndexedChange.bind(this, 'habilidades_ti', 'años_experiencia')}
              isDisabled={!this.props.is_answerable}
              hasStar={false}
            />
          </div>

          <h2>Hobbies</h2>
          {this.state.incompleted_error['hobbies'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> { this.state.incompleted_message } </div> :
            null}
          <div className={`profile-form-box form-column ${this.missingClass('hobbies')}` }>
            <SimpleInputSection
              handleItemClick={this.handleItemClick.bind(this, 'hobbies')}
              items={fields.hobbies}
              missingClass={this.missingClass.bind(this, 'hobbies')}
              setStarred={this.setStarred.bind(this, 'hobbies')}
              starredClass={this.starredClass.bind(this, 'hobbies')}
              firstField={'Nombre'}
              firstFieldAccessor={'nombre'}
              firstPlaceHolder={'Ej. Leer'}
              handleFirstFieldChange={this.handleIndexedChange.bind(this, 'hobbies', 'nombre')}
              isDisabled={!this.props.is_answerable}
              hasStar={false}
            />
          </div>

          <h2>Otras Habilidades</h2>
          {this.state.incompleted_error['otras_habilidades'] ?
            <div className={'alert alert-wide alert-danger'} role="alert"> { this.state.incompleted_message } </div> :
            null}
          <div className={`profile-form-box form-column ${this.missingClass('otras_habilidades')}` }>
            <SimpleInputSection
              handleItemClick={this.handleItemClick.bind(this, 'otras_habilidades')}
              items={fields.otras_habilidades}
              missingClass={this.missingClass.bind(this, 'otras_habilidades')}
              setStarred={this.setStarred.bind(this, 'otras_habilidades')}
              starredClass={this.starredClass.bind(this, 'otras_habilidades')}
              firstField={'Nombre'}
              firstFieldAccessor={'nombre'}
              firstPlaceHolder={'Ej. Manejo de Maquinaria'}
              handleFirstFieldChange={this.handleIndexedChange.bind(this, 'otras_habilidades', 'nombre')}
              isDisabled={!this.props.is_answerable}
              hasStar={false}
            />
          </div>

          <div className={'form-footer'}>
            <button
              title={this.state.sending ||
                !this.props.is_answerable ||
                !this.state.isValid ?
                'Completa los campos requeridos antes de enviar el formulario' :
                null}
              type="submit"
              className={'btn btn-primary'}
              onClick={this.handleSubmit.bind(this)}
              disabled={this.state.sending || !this.props.is_answerable || !this.state.isValid}>
              Enviar mis Datos
            </button>
            <button
              className={'btn btn-success'}
              onClick={this.saveAR.bind(this)}
              disabled={this.state.sending || !this.props.is_answerable}>
              Guardar sin Enviar
            </button>

            <button
              className={'btn btn-danger'}
              style={{ float: 'right' }}
              onClick={this.handleBack.bind(this)}>
              Volver
            </button>
            <button
              className={'btn btn-primary'}
              style={{ float: 'right' }}
              onClick={this.handleUploadAnother.bind(this)}
              disabled={!this.props.is_answerable}>
              Subir Otro CV
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CurricularProfileShow;
