/* eslint-disable react/prop-types */
import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

class LocationSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`item row-item ${(this.props.isItem ? '' : 'form-row')}`}>
        { this.props.enableCountry ?
          <div className={'item row-item'}>
            <CountryDropdown
              defaultOptionLabel='Selecciona'
              priorityOptions={['CL']}
              className={`form-control ${this.props.missingClass('pais')} ${this.props.extraClass}`}
              value={this.props.country}
              valueType={'short'}
              onChange={this.props.selectCountry}
              placeholder={'Selecciona'}
              disabled={this.props.isDisabled}
            />
          </div> :
          null }

        { this.props.enableRegion ?
          <div className={'item row-item'}>
            <label>{this.props.regionLabel}</label>
            <RegionDropdown
              defaultOptionLabel='Elige tu Región'
              className={`form-control ${this.props.missingClass('region')} ${this.props.extraClass}`}
              country={this.props.country}
              countryValueType='short'
              value={this.props.region}
              onChange={this.props.selectRegion}
              placeholder={'Selecciona'}
              disabled={this.props.isDisabled}
            />
          </div> :
          null }

        { this.props.enableCity ?
          <div className={'item row-item'}>
            <label>{this.props.cityLabel}</label><strong className={'mandatory-field'}>(*)</strong>
            <input type='string'
              onChange={this.props.changeCity}
              value={this.props.city}
              className={`form-control ${this.props.missingClass('ciudad')} ${this.props.extraClass}`}
              placeholder={'Selecciona'}
              disabled={this.props.isDisabled}
            />
          </div> :
          null }
      </div>
    );
  }
}

export default LocationSelector;
