// utlilidad para marcar las referencias con rojo o limipiarlas
/* QUE DATOS RECIBE:
** refElement,
** marcar o no como error TRUE = MARCAR, FALSE = DESMARCAR,
** <p> tag para el mensaje de error (PUEDE O NO VENIR)
*/

export default function errorHandler(element, bool, pTagElement) {
  if (bool) {
    element.style.border = '1px solid #B71C1C';
    if (pTagElement) pTagElement.style.display = 'block';
  } else {
    element.style.border = '1px solid #b4b4b4';
    if (pTagElement) pTagElement.style.display = 'none';
  }
}
