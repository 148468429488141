/* eslint-disable max-statements */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import AdviceBox from '../Common/advice_box';
import errorHandler from '../Common/errorHandler';
import Modal from '../Common/Modal';
import AddSection from '../../../assets/images/add_icon_talana.png';
import Trash from '../../../assets/images/trash_icon.png';
import negativeHandler from '../Common/negativeHandler';

class OtherSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.handleOthersChange = this.handleOthersChange.bind(this);
    this.hobbieRef = React.createRef();
    this.idiomaRef = [];
    this.dominioRef = [];
    this.habilidadesRef = React.createRef();
    this.confirmCV = this.confirmCV.bind(this);
    this.state = {
      otrasHabilidadesText: '',
      hobbiesText: '',
      fields: {
        lenguajes: [],
        hobbies: [],
        habilidades_ti: [],
        otras_habilidades: [],
      },
      mandatoryFields: {
        lenguajes: false,
        hobbies: false,
        habilidades_ti: false,
        otras_habilidades: false,
      },
      openConfirmModal: false,
      languageLevelOptions: ['Básico', 'Medio', 'Avanzado', 'Nativo'],
    };
  }

  componentWillUnmount() {
    this.props.updateFields(this.state.fields); // actualizo el estado en el componente padre
  }
  componentDidMount() {
    const fields = { ...this.props.fields };
    const mandatoryFields = { ...this.props.mandatoryFields };

    if (fields.lenguajes.length === 0) fields.lenguajes.push({ idioma: '', dominio: '' });
    if (fields.habilidades_ti.length === 0) fields.habilidades_ti.push({ nombre: '', años_experiencia: '' });

    this.setState({
      otrasHabilidadesText: '',
      hobbiesText: '',
      fields: {
        lenguajes: fields.lenguajes,
        hobbies: fields.hobbies,
        habilidades_ti: fields.habilidades_ti,
        otras_habilidades: fields.otras_habilidades,
      },
      mandatoryFields: {
        lenguajes: mandatoryFields.lenguajes,
        hobbies: mandatoryFields.hobbies,
        habilidades_ti: mandatoryFields.habilidades_ti,
        otras_habilidades: mandatoryFields.otras_habilidades,
      },
      openConfirmModal: false,
    });
  }
  removeEntry(name, entry) {
    const fields = this.state.fields;
    fields[name].splice(entry, 1);
    this.setState({ fields });
  }

  addEntry(name) {
    const fields = this.state.fields;
    switch (name) {
      case 'lenguajes':
        fields[name].push({ idioma: '', dominio: '' });
        break;
      case 'habilidades_ti':
        fields[name].push({ nombre: '', años_experiencia: '' });
        break;
      default:
        break;
    }

    this.setState({ fields });
  }
  handleOthersChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }
  handleKeyDown(event) {
    let name = '';
    if (event.target.name === 'otrasHabilidadesText') {
      name = 'otras_habilidades';
    } else if (event.target.name === 'hobbiesText') {
      name = 'hobbies';
    }
    if (event.target.value !== '') {
      const { fields } = this.state;
      const hasOne = fields[name].some(x => x.nombre === event.target.value);
      if ((event.key === 'Enter' || event.key === 'Tab') && !hasOne) {
        fields[name].push({ nombre: event.target.value, starred: false });
        this.setState({ fields, [event.target.name]: '' });
      }
    }
  }
  validateInputs(nextAction, name) {
    let flag = true;
    this.state.fields.lenguajes.forEach((lenguaje, index) => {
      const idiomaP = document.querySelector(`#idiomaRef${index}`);
      const dominioP = document.querySelector(`#dominioRef${index}`);
      if (this.idiomaRef[index].value === '') {
        // refElement, marcar o no(true, false dependiendo), p tag para el mensaje de error
        errorHandler(this.idiomaRef[index], true, idiomaP);
        flag = false;
      } else {
        errorHandler(this.idiomaRef[index], false, idiomaP);
      }
      if (this.dominioRef[index].value === '') {
        errorHandler(this.dominioRef[index], true, dominioP);
        flag = false;
      } else {
        errorHandler(this.dominioRef[index], false, dominioP);
      }
    });
    if (flag) {
      if (nextAction === 'addEntry') this.addEntry(name);
      if (nextAction === 'handleBack') this.handleBack();
      if (nextAction === 'handleNext') this.handleNext();
      this.setState({ flagUncompleted: true });
    } else {
      this.setState({ flagUncompleted: false });

      return;
    }
  }
  handleInputChange(event) {
    const fields = this.state.fields;
    const { value, name } = event.target;
    const { field, entry } = event.target.dataset;
    if (name === 'años_experiencia' && event.key) {
      negativeHandler(event);
    }
    switch (field) {
      case 'lenguajes':
      case 'habilidades_ti':
        fields[field][entry][name] = value;
        break;
      default:
        fields[field] = value;
    }
    this.setState({ fields });
  }

  handleInputFocus(ref) {
    if (ref === 'habilidades') this.habilidadesRef.focus();
    if (ref === 'hobbies') this.hobbieRef.focus();
  }
  confirmCV(isOk, props) {
    if (isOk) {
      this.handleNext(this.state.fields);
    } else {
      this.setState({ openConfirmModal: !this.state.openConfirmModal });
    }
  }
  handleRemove(value, property) {
    const actualValues = this.state.fields[property];
    const newValues = actualValues.filter(values => values !== value);
    this.setState({ ...this.state, fields: { ...this.state.fields, [property]: newValues } });
  }
  handleBack() {
    this.props.handleBack(this.state.fields);
  }

  handleNext() {
    this.props.handleNext(this.state.fields);
  }

  render() {
    const fields = this.state.fields;

    return (
      <>
        {/* IDIOMAS */}

        { fields.lenguajes.map((lang, idx) => {
          return (
            <div className={'profile-form-box form-column p-5 gap-3'} key={idx}>
              { idx <= 0 && (
                <h3 className={'other__title'}>Idiomas</h3>
              )}
              { idx > 0 && (
                <div className="row justify-content-end">
                  <div className="">
                    <button
                      className={'education__icons--trash'}
                      onClick={() => this.removeEntry('lenguajes', idx)}
                    >
                      <img src={Trash} alt="X" />
                    </button>
                  </div>
                </div>
              )}
              <div className={'row gap-3'}>
                <div className={'col-sm'}>
                  <label className={'personal__label'}>Idioma</label><strong className={'mandatory-field'}>(*)</strong>
                  <input
                    ref={(ref) => { this.idiomaRef[idx] = ref; }}
                    type="string"
                    name="idioma"
                    className={'form-control cp-input'}
                    placeholder={'Ingresa idioma'}
                    value={lang.idioma}
                    disabled={!this.props.isAnswerable}
                    data-field="lenguajes"
                    data-entry={idx}
                    onChange={this.handleInputChange}
                  />
                  <p id={`idiomaRef${idx}`} className="error-message "> Selecciona un idioma </p>
                </div>
                <div className={'col-sm'}>
                  <label className={'personal__label'}>Dominio</label><strong className={'mandatory-field'}>(*)</strong>
                  <select
                    ref={(ref) => { this.dominioRef[idx] = ref; }}
                    name="dominio"
                    className="form-select cp-input"
                    disabled={!this.props.isAnswerable}
                    value={lang.dominio}
                    data-field="lenguajes"
                    data-entry={idx}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Selecciona</option>
                    {this.state.languageLevelOptions.map((level, i) => {
                      return <option key={i} value={level}>{level}</option>;
                    })}
                  </select>
                  <p id={`dominioRef${idx}`} className="error-message"> Selecciona un dominio </p>
                </div>
              </div>
            </div>
          );
        })}

        <div className={'education__add'}>
          <button
            className={'education__add-button'}
            name="lenguajes"
            onClick={() => this.validateInputs('addEntry', 'lenguajes') }
          >
            <img src={AddSection} alt="+"/>
            Agregar idioma
          </button>
        </div>

        {/* HABILIDADES TI */}

        {fields.habilidades_ti.map((hab, idx) => {
          return (
            <div className={'other__box profile-form-box form-column p-5 gap-3'} key={idx}>
              { idx <= 0 && (
                <h3 className={'other__title'}>Habilidades TI</h3>
              )}
              { idx > 0 && (
                <div className="row justify-content-end">
                  <div className="item row-item">
                    <button
                      className={'education__icons--trash'}
                      onClick={() => this.removeEntry('habilidades_ti', idx)}
                    >
                      <img src={Trash} alt="X" />
                    </button>
                  </div>
                </div>
              )}
              <div className={'row gap-3'}>
                <div className={'col-sm'}>
                  <label className={'personal__label'}>Habilidad</label>
                  <input
                    type="string"
                    name="nombre"
                    className={'form-control cp-input'}
                    placeholder={'Ingresa habilidad'}
                    value={hab.nombre}
                    disabled={!this.props.isAnswerable}
                    data-field="habilidades_ti"
                    data-entry={idx}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={'col-sm'}>
                  <label className={'personal__label'}>Años de experiencia</label>
                  <input
                    type="number"
                    name="años_experiencia"
                    min="0"
                    onPaste= {(e) => e.preventDefault()}
                    className={'form-control cp-input'}
                    placeholder={'Selecciona'}
                    value={hab.años_experiencia || ''}
                    disabled={!this.props.isAnswerable}
                    data-field="habilidades_ti"
                    data-entry={idx}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className={'education__add'}>
          <button
            className={'education__add-button'}
            name="habilidades_ti"
            onClick={ () => this.addEntry('habilidades_ti') }
          >
            <img src={AddSection} alt="+"/>
            Agregar habilidad TI
          </button>
        </div>

        {/* OTRAS HABILIDADES */}

        <div className={'other__box profile-form-box form-column p-5 gap-3'}>
          <div className={'row'}>
            <div className={'col'}>
              <h3 className={'education__title'}>Otras habilidades</h3>
            </div>
            <div className={'col-sm'}>
              <AdviceBox messages={['Presiona la tecla Enter para agregar una nueva habilidad']}></AdviceBox>
            </div>
          </div>

          <div className={'col'}>
            <label className={'personal__label'}>Habilidades</label>
            <div onClick={ () => this.handleInputFocus('habilidades') } className="inputTag__container">
              { this.state.fields.otras_habilidades.map((habilidad) => {
                return (
                  <div className="inputTag__box" key={habilidad.nombre}>
                    <div className="inputTag__tags">{habilidad.nombre}</div>
                    <button
                      className="inputTag__closeButton"
                      name="otras_habilidades"
                      onClick={() => this.handleRemove(habilidad, 'otras_habilidades')}
                    >&times;</button>
                  </div>
                );
              })}
              <input
                ref={ref => { this.habilidadesRef = ref; }}
                name="otrasHabilidadesText"
                onChange={this.handleOthersChange}
                value={this.state.otrasHabilidadesText}
                onKeyDown={(e) => this.handleKeyDown(e)}
                type="text"
                placeholder={this.state.fields.otras_habilidades.length === 0 ? 'Ej: Trabajo en equipo, capacidad de adaptación, organización, etc' : ''}
                className={this.state.fields.otras_habilidades.length === 0 ? 'inputTag__input--large' : 'inputTag__input--short' }
                disabled={!this.props.isAnswerable}
              />
            </div>
          </div>
        </div>

        {/* HOBBIES */}

        <div className={'other__box profile-form-box form-column p-5 gap-3'}>
          <div className={'row'}>
            <div className={'col'}>
              <h3 className={'education__title'}>Hobbies</h3>
            </div>
            <div className={'col-sm'}>
              <AdviceBox messages={['Presiona la tecla Enter para agregar un nuevo hobby']}></AdviceBox>
            </div>
          </div>

          <div className={'col'}>
            <label className={'personal__label'}>Hobbies</label>
            <div onClick={() => this.handleInputFocus('hobbies')} className="inputTag__container">
              { this.state.fields.hobbies.map((hobbie) => {
                return (
                  <div className="inputTag__box" key={hobbie.nombre}>
                    <div className="inputTag__tags">{hobbie.nombre}</div>
                    <button
                      className="inputTag__closeButton"
                      name="hobbiesText"
                      onClick={() => this.handleRemove(hobbie, 'hobbies')}
                    >&times;</button>
                  </div>
                );
              })}
              <input
                ref={ref => { this.hobbieRef = ref; }}
                name="hobbiesText"
                onChange={this.handleOthersChange}
                value={this.state.hobbiesText}
                onKeyDown={(e) => this.handleKeyDown(e)}
                type="text"
                placeholder={this.state.fields.hobbies.length === 0 ? 'Ej: Leer, hacer trekking, viajar, etc' : ''}
                className={this.state.fields.hobbies.length === 0 ? 'inputTag__input--large' : 'inputTag__input--short' }
                disabled={!this.props.isAnswerable}
              />
            </div>
          </div>
        </div>
        { this.state.openConfirmModal && 
          <Modal
            title={'Envío de Curriculum'}
            subtitle={'Una vez que confirmes el envío, no podrás editar los datos de esta solicitud curricular.'}
            backAction={'Cancelar'}
            nextAction={'Confirmar envío'}
            functionToHandle={this.confirmCV}
          />
        }

        <div className={'d-flex justify-content-end mt-3'}>
          <button
            type="submit"
            className={'btn-form btn-form--inverse'}
            onClick={() => this.validateInputs('handleBack') }
            disabled={!this.props.isAnswerable}
          >
            Volver
          </button>
          <button
            type="submit"
            className={'btn-form'}
            onClick={() => this.setState({ openConfirmModal: !this.state.openConfirmModal })}
            disabled={!this.props.isAnswerable}
          >
            Enviar CV
          </button>
        </div>
      </>
    );
  }
}

export default OtherSection;
