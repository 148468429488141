/* eslint-disable func-style */
/* eslint-disable arrow-body-style */
import React from 'react';

const SavedBox = () => {
  return (
    <div className="saved-container">
      <p className="saved-text">
            Tus cambios han sido guardados
      </p>
    </div>
  );
};

export default SavedBox;
