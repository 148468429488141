/* eslint-disable max-statements */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import uuid from '../Common/uuid';
import errorHandler from '../Common/errorHandler';
import AdviceBox from '../Common/advice_box';
import Modal from '../Common/Modal';
import AddJob from '../../../assets/images/add_icon_talana.png';
import EmptyStar from '../../../assets/images/star_outline_icon.png';
import FullStar from '../../../assets/images/star_full_icon.png';
import Trash from '../../../assets/images/trash_icon.png';
import LocationSelector from '../../containers/LocationSelector';
import negativeHandler from '../Common/negativeHandler';

class JobSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.addEntry = this.addEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.experienciaRef = React.createRef();
    this.cargoRef = [];
    this.empleadorRef = [];
    this.ciudadRef = [];
    this.fechaInicioRef = [];
    this.state = {
      fields: {
        experiencias_laborales: [],
        años_de_experiencia: '',
        inexperienced: false,
      },
      mandatoryFields: {
        experiencias_laborales: false,
        años_de_experiencia: false,
        inexperienced: false,
      },
      current: false,
      expYears: [],
      yearsGap: 70,
      initYears: [],
      endYears: [],
      openRemoveModal: false,
      modalElement: null,
      flagUncompleted: true,
    };
  }

  componentDidMount() {
    const fields = { ...this.props.fields };
    const mandatoryFields = { ...this.props.mandatoryFields };
    if (fields.experiencias_laborales.length === 0) {
      fields.experiencias_laborales.push({
        uuid: uuid(),
        nombre_experiencia: '',
        empleador: '',
        pais_experiencia: '',
        ciudad_experiencia: '',
        fecha_inicio: '',
        fecha_termino: '',
        actual: false,
        descripcion: '',
        starred: true,
      });
    }

    this.setState({
      fields: {
        experiencias_laborales: fields.experiencias_laborales,
        años_de_experiencia: fields.años_de_experiencia,
        inexperienced: fields.inexperienced,
        // laboralHasStarred: fields.laboralHasStarred,
      },
      mandatoryFields: {
        ...mandatoryFields,
      },
      expYears: [],
      yearsGap: 70,
      initYears: [],
      endYears: [],
      openRemoveModal: false,
      modalElement: null,
      flagUncompleted: true,
    });
    this.setInitYears();
    this.setEndYears();
  }

  // Setea el país y trae las ciudades
  selectCountry(field, attribute, id, pais_experiencia) {
    const fields = this.state.fields;
    const newJob = this.state.fields.experiencias_laborales.map(laboral => {
      if (laboral.uuid === id) {
        return { ...laboral, pais_experiencia: pais_experiencia };
      }

      return laboral;
    });
    fields['experiencias_laborales'] = newJob;
    this.setState({ fields });
  }

  handleInputChange(value, name, index, event) {
    if (name === 'años_de_experiencia' && event && event.key) {
      negativeHandler(event);
    }
    const fields = this.state.fields;
    if (name === 'años_de_experiencia') {
      fields.años_de_experiencia = value;
    } else if (name === 'inexperienced') {
      fields.inexperienced = !fields.inexperienced;
      fields.años_de_experiencia = '0';
      if (fields.inexperienced) {
        fields.experiencias_laborales = [{ uuid: uuid() }];
      } else {
        fields.experiencias_laborales = [{
          uuid: uuid(),
          nombre_experiencia: '',
          empleador: '',
          pais_experiencia: '',
          ciudad_experiencia: '',
          fecha_inicio: '',
          fecha_termino: '',
          actual: false,
          descripcion: '',
          starred: true,
        }];
      }
    } else if (name === 'actual') {
      fields.experiencias_laborales[index][name] = !fields.experiencias_laborales[index][name];
      fields.experiencias_laborales[index]['fecha_termino'] = '';
    } else {
      fields.experiencias_laborales[index][name] = value;
    }
    this.setState({ fields });
  }

  handleBack() {
    this.props.handleBack(this.state.fields);
  }

  handleNext() {
    this.props.handleNext(this.state.fields);
  }

  addEntry() {
    const fields = this.state.fields;

    fields['experiencias_laborales'].push({
      uuid: uuid(),
      nombre_experiencia: '',
      empleador: '',
      pais_experiencia: '',
      ciudad_experiencia: '',
      fecha_inicio: '',
      fecha_termino: '',
      actual: false,
      descripcion: '',
      starred: false,
    });
    this.setState({ fields });
  }

  validateInputs(nextAction) {
    const fields = this.state.fields;
    let flag = true;

    if (!fields.inexperienced) {
      this.state.fields.experiencias_laborales.forEach((laboral, index) => {
        const experienciaP = document.querySelector(`#experienciaRef${index}`);
        const cargoP = document.querySelector(`#cargoRef${index}`);
        const empleadorP = document.querySelector(`#empleadorRef${index}`);
        const ciudadP = document.querySelector(`#ciudadRef${index}`);
        const fechaInicioP = document.querySelector(`#fechaInicioRef${index}`);

        if (this.experienciaRef.value === '') {
          errorHandler(this.experienciaRef, true, experienciaP);
          flag = false;
        } else {
          errorHandler(this.experienciaRef, false, experienciaP);
        }

        if (this.cargoRef[index].value === '') {
          errorHandler(this.cargoRef[index], true, cargoP);
          flag = false;
        } else {
          errorHandler(this.cargoRef[index], false, cargoP);
        }

        if (this.empleadorRef[index].value === '') {
          errorHandler(this.empleadorRef[index], true, empleadorP);
          flag = false;
        } else {
          errorHandler(this.empleadorRef[index], false, empleadorP);
        }

        if (this.ciudadRef[index].value === '') {
          errorHandler(this.ciudadRef[index], true, ciudadP);
          flag = false;
        } else {
          errorHandler(this.ciudadRef[index], false, ciudadP);
        }

        if (this.fechaInicioRef[index].value === '') {
          errorHandler(this.fechaInicioRef[index], true, fechaInicioP);
          document.querySelector(`#actual${index}`).style.marginBottom = '30px';
          flag = false;
        } else {
          document.querySelector(`#actual${index}`).style.marginBottom = '0px';
          errorHandler(this.fechaInicioRef[index], false, fechaInicioP);
        }
      });
    }

    if (flag) {
      if (nextAction === 'addEntry') this.addEntry();
      if (nextAction === 'handleBack') this.handleBack();
      if (nextAction === 'handleNext') this.handleNext();
      this.setState({ flagUncompleted: true });
    } else {
      this.setState({ flagUncompleted: false });

      return;
    }
  }

  updateModal(laboral) {
    if (laboral.nombre_experiencia === '' && laboral.empleador === '' && laboral.pais_experiencia === '' &&
    laboral.ciudad_experiencia === '' && laboral.fecha_inicio === '' && laboral.fecha_termino === '' &&
    laboral.descripcion === '') {
      this.confirmedRemoveEntry('experiencias_laborales', laboral.uuid, laboral.starred);

      return;
    }
    let modalElement = this.state.modalElement;
    modalElement = <Modal
      title={'Eliminar experiencia laboral'}
      subtitle={
        `Al eliminar este contenido no podrás recuperar esta información.
      Recuerda que debes tener al menos un item de experiencia laboral para avanzar.`
      }
      backAction={'Cancelar'}
      nextAction={'Eliminar'}
      functionToHandle={this.removeEntry}
      name="experiencias_laborales"
      uuid={laboral.uuid}
      isStarred={laboral.starred}
    />;
    this.setState({ modalElement, openRemoveModal: true });
  }

  removeEntry(isOk, props) {
    if (isOk) {
      this.confirmedRemoveEntry(props.name, props.uuid, props.isStarred);
    } else {
      this.setState({ openRemoveModal: false });
    }
  }

  confirmedRemoveEntry(name, id, isStarred) {
    const fields = this.state.fields;
    const newJob = fields.experiencias_laborales.filter(laboral => laboral.uuid !== id);
    fields.experiencias_laborales = newJob;
    if (isStarred) {
      fields.experiencias_laborales[0].starred = true;
    }
    this.setState({ fields, openRemoveModal: false });
  }

  addStar(uid, isStarred) {
    // if (!this.state.fields.laboralHasStarred || isStarred) {
    const fields = this.state.fields;
    const newJob = fields.experiencias_laborales.map(laboral => {
      if (laboral.uuid === uid) {
        return { ...laboral, starred: true };
      }

      return { ...laboral, starred: false };
    });
    fields.experiencias_laborales = newJob;
    // fields.laboralHasStarred = !fields.laboralHasStarred;
    this.setState({ fields });
    // }
  }

  setInitYears() {
    const currentYear = new Date().getFullYear();
    const dateArr = [];

    for (let i = currentYear - this.state.yearsGap; i < currentYear + 1; i++) {
      dateArr.unshift(i);
    }

    this.setState({ initYears: dateArr });
  }

  setEndYears() {
    const currentYear = new Date().getFullYear();
    const dateArr = [];
    const lessYears = 19;

    for (let i = currentYear - this.state.yearsGap; i < currentYear + this.state.yearsGap - lessYears; i++) {
      dateArr.unshift(i);
    }

    this.setState({ endYears: dateArr });
  }

  render() {
    const fields = this.state.fields;

    return (
      <>
        { fields.experiencias_laborales && fields.experiencias_laborales.map((laboral, index) => {
          return (
            <div className={'vstack p-4 p-md-5 mt-3 bg-white shadow-sm gap-3'} key={laboral.uuid}>
              { this.state.openRemoveModal && this.state.modalElement }

              { index < 1 && (
                <div className={'row align-items-center gap-3'}>
                  <div className={'col'}>
                    <h3>Experiencia Laboral</h3>
                  </div>
                  <div className={'col-auto'}>
                    <input
                      id="inexperiencedCheck"
                      type="checkbox"
                      name="inexperienced"
                      className={''}
                      checked={fields.inexperienced}
                      onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                      disabled={!this.props.isAnswerable}
                    />
                    <label className={'job__inexperienced--text'} htmlFor="inexperiencedCheck">No tengo experiencia</label>
                  </div>
                </div>
              )}

              { fields.inexperienced && (
                <div className={'row'}>
                  <div className={'job__no-experience w-100 p-4'}>
                    <p>Puedes continuar con tu solicitud</p>
                  </div>
                </div>
              )}

              { !fields.inexperienced && (
                <div className='vstack gap-3'>
                  { index < 1 && (
                    <>
                      <div className={'row gap-3'}>
                        <div className={'col-auto col-md-4'}>
                          <label className={'personal__label'}>Años de experiencia</label><strong className={'mandatory-field'}>(*)</strong>
                          <input
                            ref={(ref) => { this.experienciaRef = ref; }}
                            type='number'
                            name='años_de_experiencia'
                            className={'form-control cp-input'}
                            value={fields.años_de_experiencia}
                            min='0'
                            onPaste= {(e) => e.preventDefault()}
                            onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index, e)}
                            onKeyDown={(e) => this.handleInputChange(e.target.value, e.target.name, index, e)}
                            disabled={!this.props.isAnswerable}
                          />
                          <p id={`experienciaRef${index}`} style={{ color: '#B71C1C', fontSize: '14px', display: 'none' }}> Selecciona años de experiencia </p>
                        </div>
                        <div className={'col-sm'}>
                          <AdviceBox messages={['Identifica tu experiencia laboral  más importante presionando la estrella']}></AdviceBox>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}

                  <div>
                    <ReactTooltip place="top" type="dark" effect="solid" id="Star">
                      <span>Importante</span>
                    </ReactTooltip>
                    <button
                      className={'education__icons--button'}
                      data-tip
                      data-for="Star"
                      onClick={() => this.addStar(laboral.uuid, laboral.starred)}
                    >
                      <img id={'star'} src={laboral.starred ? FullStar : EmptyStar} alt="*"/>
                    </button>
                    { index > 0 && (
                      <button
                        className={'education__icons--button education__icons--trash'}
                        onClick={() => this.updateModal(laboral)}
                      >
                        <img src={Trash} alt="X"/>
                      </button>
                    )}
                  </div>

                  <div className={'row gap-3'}>
                    <div className={'col-sm'}>
                      <label className={'personal__label'}>Cargo</label><strong className={'mandatory-field'}>(*)</strong>
                      <input
                        ref={(ref) => { this.cargoRef[index] = ref; }}
                        type="string"
                        name="nombre_experiencia"
                        className={'form-control cp-input'}
                        placeholder={'Ej: Ejecutivo de ventas'}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        value={laboral.nombre_experiencia}
                        disabled={!this.props.isAnswerable}
                      />
                      <p id={`cargoRef${index}`} style={{ color: '#B71C1C', fontSize: '14px', display: 'none' }}> Ingresa el cargo </p>
                    </div>
                    <div className={'col-sm'}>
                      <label className={'personal__label'}>Empleador</label><strong className={'mandatory-field'}>(*)</strong>
                      <input
                        ref={(ref) => { this.empleadorRef[index] = ref; }}
                        type="string"
                        name="empleador"
                        className={'form-control cp-input'}
                        placeholder={'Ej: KeyClouding'}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        value={laboral.empleador}
                        disabled={!this.props.isAnswerable}
                      />
                      <p id={`empleadorRef${index}`} style={{ color: '#B71C1C', fontSize: '14px', display: 'none' }}> Ingresa el empleador </p>
                    </div>
                  </div>

                  <div className={'row gap-3'}>
                    <div className={'col-sm'}>
                      <label className={'personal__label'}>País</label><strong className={'mandatory-field'}>(*)</strong>
                      <LocationSelector
                        enableCountry={true}
                        enableRegion={false}
                        enableCity={false}
                        extraClass={'item row-item cp-input'}
                        country={laboral.pais_experiencia}
                        selectCountry={this.selectCountry.bind(this, 'experiencias_laborales', 'pais_experiencia', laboral.uuid)}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        missingClass={() => false}
                        isItem={true} /* Esto dice si este selector tiene su propia fila o si comparte con otros */
                        isDisabled={!this.props.isAnswerable}
                      />
                    </div>
                    <div className={'col-sm'}>
                      <label className={'personal__label'}>Ciudad</label><strong className={'mandatory-field'}>(*)</strong>
                      <input
                        ref={(ref) => { this.ciudadRef[index] = ref; }}
                        type="string"
                        name="ciudad_experiencia"
                        className={'form-control cp-input'}
                        placeholder={'Ingresa'}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        value={laboral.ciudad_experiencia}
                        disabled={!this.props.isAnswerable}
                      />
                      <p id={`ciudadRef${index}`} style={{ color: '#B71C1C', fontSize: '14px', display: 'none' }}> Ingresa la ciudad </p>
                    </div>
                  </div>

                  <div className={'row justify-content-between gap-3'}>
                    <div className={'col-md-5 row gap-2'}>
                      <div className='col'>
                        <label className={'personal__label'}>Fecha inicio</label><strong className={'mandatory-field'}>(*)</strong>
                        <select
                          ref={(ref) => { this.fechaInicioRef[index] = ref; }}
                          name="fecha_inicio"
                          className={'form-select cp-input'}
                          value={laboral.fecha_inicio}
                          onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                          disabled={!this.props.isAnswerable}
                        >
                          <option value=''>Año</option>
                          { this.state.initYears.map((date, i) => {
                            return <option key={i} value={date}>{date}</option>;
                          })}
                        </select>
                        <p id={`fechaInicioRef${index}`} style={{ color: '#B71C1C', fontSize: '14px', display: 'none' }}> Selecciona fecha de inicio </p>
                      </div>

                      { !laboral.actual && laboral.fecha_inicio && (
                        <div className='col'>
                          <label className={'personal__label'}>Fecha término</label>
                          <select
                            name="fecha_termino"
                            className={'form-select cp-input'}
                            value={laboral.fecha_termino}
                            onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                            disabled={!this.props.isAnswerable || laboral.actual}
                          >
                            { this.state.endYears.map((date, i) => {
                              if (laboral.fecha_inicio && laboral.fecha_inicio <= date)
                                return <option key={i} value={date}>{date}</option>;

                              return null;
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                    <div id={`actual${index}`} className={'d-flex col-sm col-md-6 align-items-center'}>
                      <input
                        id={`isCurrentCharge${index}`}
                        type="checkbox"
                        name="actual"
                        className={'job__inexperienced--check'}
                        checked={laboral.actual}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        disabled={!this.props.isAnswerable}
                      />
                      <label htmlFor={`isCurrentCharge${index}`} className={'job__inexperienced--text'}>Actualmente tengo este cargo</label>
                    </div>
                  </div>

                  <div className={'row'}>
                    <div className={'col-sm'}>
                      <label className={'personal__label'}>Descripción</label>
                      <textarea
                        name="descripcion"
                        className={'personal__textarea form-control'}
                        placeholder={'Cuéntanos qué aprendiste'}
                        onChange={(e) => this.handleInputChange(e.target.value, e.target.name, index)}
                        value={laboral.descripcion}
                        rows={6}
                        disabled={!this.props.isAnswerable}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}

        { !fields.inexperienced && (
          <div className={'education__add'}>
            <button
              className={'education__add-button'}
              name="experiencias_laborales"
              onClick={() => this.validateInputs('addEntry')}
            >
              <img src={AddJob} alt="+"/>
              Agregar experiencia
            </button>
          </div>
        )}

        <div className={'d-flex justify-content-end mt-3'}>
          <button
            type="submit"
            className={'btn-form btn-form--inverse'}
            onClick={() => this.validateInputs('handleBack') }
            disabled={!this.props.isAnswerable}
          >
            Volver
          </button>
          <button
            type="submit"
            className={'btn-form'}
            onClick={() => this.validateInputs('handleNext')}
            disabled={!this.props.isAnswerable}
          >
            Siguiente
          </button>
        </div>
      </>
    );
  }
}

export default JobSection;
