/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import axios from 'axios';
import Icon from '@mdi/react';
import { mdiFileOutline } from '@mdi/js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from 'react-spinners/ClipLoader';
import Dropzone from 'react-dropzone';
import TopBanner from '../containers/TopBanner';
import KsBreadcrumb from './Common/Breadcrumb';
import AlertBox from './Common/AlertBox';

class CurricularProfileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apikey: '',
      files: null,
      error: '',
      success: '',
      loading: false,
    };
  }

  componentDidMount() {
    const search = window.location.search;
    const apikey = (new URLSearchParams(search)).get('apikey');
    console.log(this.props.isSelfRequested);
    this.setState({ apikey });

    if (this.props.is_answerable === false) {
      window.location.href = `${this.props.applicantPortal}/?apikey=${this.state.apikey}`;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ success: '', error: '', loading: true }, () => {
      const files = this.state.files;
      if (files && files.length > 0) {
        const formData = new FormData();
        formData.append('cv_file', files[0]);
        axios.post(`/api/v1/applicant/curricular_profiles/${this.props.cp_id}/responses/${this.props.ar_id}/parse_cv_sync`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data', apikey: this.state.apikey } },
        )
          .then(res => {
            this.setState({ loading: false });
            localStorage.setItem('attachedFileName', this.state.files[0].name);
            window.location.href = `/applicant/curricular_profiles/${this.props.cp_id}?code=success&apikey=${this.state.apikey}&ar_id=${this.props.ar_id}`;
          })
          .catch(error => {
            console.log(error.response);
            if (error.response.data.messages.includes('ERROR: 415')) {
              this.setState({ error: 'Tu documento no puede ser procesado ya que su origen es una imagen convertida a pdf. Intenta con otro documento cuyo origen sea texto.', loading: false });
            } else {
              this.setState({ error: error.response.data.messages, loading: false });
            }
          });
      }
    });
  }

  onFileChange(e) {
    const files = e;
    this.setState({ files }, () => console.log(this.state.files[0]));
  }

  onFileEventChange(e) {
    const files = e.target.files;
    this.setState({ files }, () => console.log(this.state.files[0]));
  }

  deleteFile(e) {
    e.preventDefault();
    this.setState({ files: null });
  }

  render() {
    const success = (this.state.success === '' ? null : <AlertBox
      message={'Tu CV ha sido procesado exitosamente.'}
      boldMessage={'Para completar tu solicitud, confirma los datos que aparecen a continuación'}
      className={'alert-box alert-box--info p-3'}
    />);
    const error = (this.state.error === '' ? null : <div className={'alert alert-danger horizontal-center vertical-spacing uploader-alert'} role="alert"> {this.state.error} </div>);
    const loading = (this.state.loading === false ? null : <div className={'alert alert-info horizontal-center vertical-spacing uploader-alert'} role="alert"> {'Estamos procesando tu currículum. Este proceso puede demorar hasta un minuto.'} </div>);
    const backUrl = `/applicant/curricular_profiles/${this.props.cp_id}?override=true&apikey=${this.state.apikey}&ar_id=${this.props.ar_id}`;
    const breadlist = [{ label: 'Inicio', value: `${this.props.applicantPortal}/?apikey=${this.state.apikey}` }, { label: 'Subir CV', value: `/applicant/curricular_profiles/${this.props.cp_id}/upload_cv?apikey=${this.state.apikey}&ar_id=${this.props.ar_id}` }];

    return (
      <div>
        <TopBanner apikey={this.state.apikey} name={this.props.applicantName}/>
        <div className="container">
          <KsBreadcrumb elements={breadlist}/>
          <div className={'vertical-center horizontal-center vertical-container'}>
            <div className={'uploader-descriptor'}>
              <p className={'uploader-title'}>Currículum</p>
              <p className={'uploader-subtitle'}>Una vez finalizada la carga de tu CV, podrás editar el contenido y confirmar su envío</p>
            </div>
            {!this.props.isSelfRequested &&
              <div className={'uploader-offer-company-descriptor'}>
                <div className={'bold-text flexbox'}>
                  Oferta Laboral: <div className={'uploader-descriptor-subtext'}>{this.props.offerName}</div>
                </div>
                <p className={'uploader-descriptor-subtext uploader-separator'}>|</p>
                <div className={'bold-text flexbox'}>
                  Organización: <div className={'uploader-descriptor-subtext'}>{this.props.companyName}</div>
                </div>
              </div>
            }
            <div className="container">
              <div className="uploader-upload-box p-3 p-md-5 w-100">
                <div className={'form-column'}>
                  {
                    this.state.loading ?
                      <ClipLoader
                        css={'display: block; margin: 0 auto; border-color:#337AB7 #337AB7 transparent #337AB7;'}
                        sizeUnit={'px'}
                        size={150}
                        color={'#123abc'}
                        loading={this.state.loading}
                      /> : null
                  }
                  { this.state.files ?
                    <div>
                      <button
                        type="button"
                        className={'upload-btn py-2 px-3'}
                        disabled={this.state.loading || !this.props.is_answerable}
                        onClick={this.handleSubmit.bind(this)}>
                          Aceptar y continuar
                      </button>
                      <p className={'upload-subtext mt-3'}><b>Archivo seleccionado:</b> {this.state.files[0].name}</p>
                      { this.state.loading ? null : <a className={'manually-upload'} href={''} onClick={this.deleteFile.bind(this)}><u>Eliminar Archivo Seleccionado</u></a> }
                    </div> :
                    <Dropzone className={'dropzone'} onDrop={acceptedFiles => this.onFileChange(acceptedFiles)}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className={'dragzone'}>
                              <Icon className={'upload-icon'} path={mdiFileOutline} size={2}/>
                              <p className={'upload-subtext'}>Arrastra y suelta aquí tu CV o</p>
                              <button
                                type="button"
                                className={'upload-btn'}
                                disabled={this.state.loading || !this.props.is_answerable}>
                                  Selecciona el archivo (.PDF o .DOCX)
                              </button>                          
                            </div>                        
                          </div>
                          <a className={'manually-upload'} href={backUrl}>Llenar Manualmente los Datos</a>
                        </section>
                      )}
                    </Dropzone>
                  }
                  {success}
                  {error}
                  {loading}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CurricularProfileUpload;
