import React from 'react';
import { faMinusSquare, faPlusSquare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SimpleInputSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setSecondField(type, element, index) {
    if (type == 'select') {
      return (
        <select className={'form-control cp-input'} onChange={(e) => this.props.handleSecondFieldChange(index, e)} value={element[this.props.secondFieldAccessor]} disabled={this.props.isDisabled}>
          {this.props.secondFieldOptions.map((opt, i) => (<option key={i}>{opt}</option>))}
        </select>
      );
    } else if (type == 'numeric') {
      return (
        <input className={'form-control cp-input'} placeholder={this.props.secondPlaceHolder} type="number" onChange={(e) => this.props.handleSecondFieldChange(index, e)} value={element[this.props.secondFieldAccessor]} disabled={this.props.isDisabled}/>
      );
    }

    return null; /* Nunca deberíamos llegar acá, btw. */
  }

  render() {
    return (
      <div className={'form-column item form-item-2'}>
        <div className={'form-item-div'}>
          <div className={'form-item-header'}>
            <h4>{this.props.sectionTitle}</h4>
            { this.props.isDisabled ?
              null :
              <div className={'form-item-header-buttons'}>
                <FontAwesomeIcon onClick={() => this.props.handleItemClick(1, null, this.props.secondFieldOptions)} className={'form-item-header-button'} icon={faPlusSquare}/>
              </div>
            }
          </div>
          { [...this.props.items].map((e, i) => (
            <div key={e.id} className={`${this.props.missingClass}`}>
              <div className={'form-row'}>
                {this.props.hasStar ? <FontAwesomeIcon onClick={() => this.props.setStarred(i)} className={`form-item-header-starred ${this.props.starredClass(i)}${this.props.isDisabled ? ' unclickable' : ''}`} icon={faStar}/> : <div></div> }
                <FontAwesomeIcon onClick={() => this.props.handleItemClick(-1, i)} className={'form-item-header-button'} icon={faMinusSquare}/>
              </div>
              <div className={'form-row'}>
                <div className={'item row-item'}>
                  <label>{this.props.firstField}</label><strong className={'mandatory-field'}>(*)</strong>
                  <input type="string" placeholder={this.props.firstPlaceHolder} onChange={(e) => this.props.handleFirstFieldChange(i, e)} value={e[this.props.firstFieldAccessor]} className={'form-control cp-input'} disabled={this.props.isDisabled}/>
                </div>
                {
                  this.props.secondField ?
                    <div className={'item row-item'}>
                      <label>{this.props.secondField}</label><strong className={'mandatory-field'}>(*)</strong>
                      {this.setSecondField(this.props.secondFieldType, e, i)}
                    </div> :
                    null
                }
              </div>
              <hr/>
            </div>
          ),
          )}
        </div>
      </div>
    );
  }
}

export default SimpleInputSection;
